import {
    CLEAR_ALERT,
    CLEAR_DIALOG,
    SET_ALERT,
    SET_DIALOG,
    SET_DATE,
    SET_UNIT,
    SET_TRIGGERED,
} from "./alert"
import {LOGIN, LOGOUT, RELOGIN} from "./auth"
import storage from "../utils/storage"
import {BASE_URL} from "../config/config"
import {SET_SETTINGS} from "./graphs"

const reducer = (
    state = {
        isAuthUser: storage.isAdmin(),
        user: storage.getUsername(),
        token: storage.getToken(),
        refreshToken: storage.getRefreshToken(),
        baseUrl: BASE_URL,
        isLoading: false,
        alert: null,
        dialog: false,
        isAdmin: storage.isAdmin(),
        isViewer: storage.isViewer(),
        id: storage.getId(),
        dateFrom: storage.getDateFrom(),
        dateTo: storage.getDateTo(),
        unit: storage.getUnit(),
        graphSettings: storage.getSettings(),
        triggered: storage.getTriggered(),
    },
    action
) => {
    switch (action.type) {
        case SET_TRIGGERED:
            storage.setTriggered(action.payload.triggered)

            return {
                ...state,
                triggered: action.payload.triggered,
            }

        case SET_UNIT:
            if(typeof action.payload.unit === 'object')
            {
                storage.setUnit(action.payload.unit.id)
                return {
                    ...state,
                    unit: action.payload.unit.id,
                }
            }else{
                storage.setUnit(action.payload.unit)
                return {
                    ...state,
                    unit: action.payload.unit,
                }
            }


        case SET_DATE:
            storage.setDateFrom(action.payload.dateFrom)
            storage.setDateTo(action.payload.dateTo)

            return {
                ...state,
                dateFrom: action.payload.dateFrom,
                dateTo: action.payload.dateTo,
            }

        case LOGIN:
            storage.setToken(action.payload.token)
            storage.setRefreshToken(action.payload.refreshToken)
            storage.setId(action.payload.id)
            storage.setAdmin(action.payload.isAdmin)
            storage.setViewer(action.payload.isViewer)
            storage.setUsername(action.payload.username)
            return {
                ...state,
                token: action.payload.token,
                isAuthUser: true,
                user: action.payload.username,
                refreshToken: action.payload.refreshToken,
                isAdmin: action.payload.isAdmin,
                isViewer: action.payload.isViewer,
                id: action.payload.id,
            }

        case RELOGIN:
            return {
                ...state,
                token: action.payload.token,
            }

        case LOGOUT:
            storage.clearStorage()

            return {
                ...state,
                isAuthUser: false,
                user: "",
                token: "",
                isAdmin: false,
                isViewer: false,
            }

        case SET_ALERT:
            return {...state, alert: action.payload}

        case CLEAR_ALERT:
            return {...state, alert: null}

        case SET_DIALOG:
            return {...state, dialog: action.payload}

        case CLEAR_DIALOG:
            return {...state, dialog: null}

        case SET_SETTINGS:
            storage.setSettings({...state.graphSettings, ...action.payload})
            return {...state, graphSettings: action.payload}

        default:
            return state
    }
}

export default reducer
