import {makeStyles} from "@material-ui/core/styles"
import MaterialTable from "material-table"
import React, {useEffect, useState} from "react"
import AddCustomExpr from "../modals/CustomExpressionModal"
import DeleteModal from "../../../../components/modals/DeleteModal"
import {tableIcons} from "../../../../components/tables/tableIcons"
import {useAxios} from "../../../../utils/request"

const useStyles = makeStyles((theme) => ({
    table: {
        padding: "0px",
    },
}))

export default function ExpressionsTable(props) {
    const endpointUrl = props.url ? props.url : "analog_expressions"
    const classes = useStyles()
    const [data, setData] = useState([])
    const [modalAddExpr, setModalAddExpr] = useState(false)
    const [modalDelete, setModalDelete] = useState(false)
    const [editModal, setEditModal] = useState(false)

    const getExpressionsRequest = useAxios(
        ["expressionTable", endpointUrl],
        {
            url: `/${endpointUrl}/?unit=${props.id}`,
            method: "GET",
        },
        {enabled: true},
        (res) => {
            setData(
                res.data.results.map((row) => {
                    let expressArray = Object.keys(row.intervals).map(
                        (item) => ({
                            expression: item,
                            ...row.intervals[item],
                        })
                    )
                    return {...row, expressions: JSON.stringify(expressArray)}
                })
            )
        }
    )

    useEffect(
        (_) => {
            getExpressionsRequest.refetch()
        },
        // eslint-disable-next-line
        [modalAddExpr, modalDelete]
    )

    const deleteRow = (event, rowData) => {
        setModalDelete(rowData)
    }

    return (
        <>
            <DeleteModal
                open={!!modalDelete}
                handleClose={() => {
                    setModalDelete(false)
                }}
                url={`/${endpointUrl}/${modalDelete.id}/`}
                deleteText="DELETE EXPRESSION"
                header={`Delete expression ${modalDelete.name}?`}
                alertText={"Expression was deleted"}
            />
            {modalAddExpr && (
                <AddCustomExpr
                    open={modalAddExpr}
                    url={endpointUrl}
                    id={props.id}
                    handleClose={() => {
                        setModalAddExpr(false)
                        setEditModal(false)
                    }}
                    edit={editModal}
                    isPulseExpression={props?.isPulseExpression}
                />
            )}
            <MaterialTable
                className={classes.table}
                icons={tableIcons}
                columns={[
                    {title: "Name", field: "name"},
                    {title: "Variable", field: "variable"},
                    {title: "Physical unit", field: "physical_unit"},
                    {title: "Expressions", field: "expressions"},
                    ...(props.custom_table_fields || []),
                ]}
                data={data}
                title="Expressions"
                isLoading={getExpressionsRequest.isLoading}
                actions={[
                    {
                        icon: tableIcons.Add,
                        tooltip: "Add expression",
                        isFreeAction: true,
                        onClick: (event) => setModalAddExpr(true),
                    },
                    {
                        icon: tableIcons.Edit,
                        tooltip: "Edit expression",
                        onClick: (event, rowData) => {
                            setModalAddExpr(true)
                            setEditModal(rowData)
                        },
                    },
                    {
                        icon: tableIcons.Delete,
                        tooltip: "Delete expression",
                        onClick: deleteRow,
                    },
                ]}
                options={{
                    pageSize: 10,
                    actionsColumnIndex: -1,
                    search: false,
                }}
            />
        </>
    )
}
