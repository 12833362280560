import axios from "axios"
import {setAlert} from "./alert"

export const LOGIN = "LOGIN"
export const RELOGIN = "RELOGIN"
export const LOGIN_SUCCESSFUL = "LOGIN_SUCCESSFUL"
export const LOGOUT = "LOGOUT"

// Action creators

export const login = (username, token, refreshToken, isAdmin, isViewer, id) => {
    return {
        type: LOGIN,
        payload: {username, token, refreshToken, isAdmin, isViewer, id},
    }
}

export const relogin = (token) => {
    return {
        type: RELOGIN,
        payload: {token},
    }
}

export const logout = () => {
    return {
        type: LOGOUT,
    }
}

// thunk
export const loginUser = (username, password) => {
    return (dispatch, getState) => {
        let conf = {
            url: `${getState().baseUrl}/api/token/`,
            method: "POST",
            data: {username, password},
        }

        axios(conf)
            .then((res) => {
                localStorage.setItem("token", res.data.access)
                localStorage.setItem("username", username)
                localStorage.setItem("refreshToken", res.data.refresh)
                localStorage.setItem("is_superuser", res.data.is_superuser)
                localStorage.setItem("is_viewer", res.data.is_viewer)
                localStorage.setItem("id", res.data.id)

                dispatch(
                    login(
                        username,
                        res.data.access,
                        res.data.refresh,
                        res.data.is_superuser,
                        res.data.is_viewer,
                        res.data.id
                    )
                )
            })
            .catch(() => {
                dispatch(
                    setAlert({
                        severity: "error",
                        message:
                            "You have entered an invalid username or password",
                    })
                )
            })
    }
}
