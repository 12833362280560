import React from "react"
import {Checkbox, ListItemText, ListSubheader, MenuItem} from "@material-ui/core";

export const listAlarms = (allAlarms, selectedAlarms) => {
    return (
        allAlarms.map(i=>i.data).map(({alarmType, results}) => {
            return (
                [
                    <ListSubheader>{alarmType.name}</ListSubheader>,
                    ...results.map(alarm =>
                        {
                            let isInSelected = selectedAlarms.find(i=>i.name === alarm.name && i.id === alarm.id)
                            return (
                                <MenuItem value={{
                                    ...alarm,
                                    name: alarm.name
                                }}>
                                    <Checkbox checked={!!isInSelected} />
                                    <ListItemText
                                        primary={`${alarm.name}`}
                                    />
                                </MenuItem>
                            )}
                    )
                ]
            )
        })
    )
}