import {
    ArgumentAxis,
    Chart,
    CommonSeriesSettings,
    Export,
    Format,
    Label,
    Legend,
    Margin,
    Point,
    ScrollBar,
    Series,
    Tooltip,
    ValueAxis,
    ZoomAndPan,
} from "devextreme-react/chart"
import React from "react"
import {makeStyles} from "@material-ui/core/styles"

const useStyles = makeStyles((theme) => ({
    root: {
        margin: "0px 20px",
    },
}))

export default function StepChart(props) {
    const classes = useStyles()

    function onPointClick({target: point}) {
        point.select()
    }

    function onLegendClick(prop) {
        let isVis = prop.target.isVisible()
        if (isVis) {
            prop.target.hide()
        } else {
            prop.target.show()
        }
        props.updateDigiExpressions(props.digiExpressions.map((item, index) => (index === prop.target.index ? { ...item, visible: !isVis } : item)))
    }

    const dateFormatter = ({value, _}) =>
        new Date(value).toLocaleString("sk-SK", {
            dateStyle: "medium",
            timeStyle: "medium",
        })

    const customizeTooltip = (pointInfo) => {
        return {
            html: `<p>Date: ${dateFormatter({
                value: pointInfo.point.argument,
            })}<p/> <p>Value: ${pointInfo.point.originalValue}<p/>`,
        }
    }
    return (
        <div className={classes.root}>
            <Chart
                dataSource={props.data.slice(0).reverse()}
                title="Digital expressions"
                onPointClick={onPointClick}
                onLegendClick={onLegendClick}
            >
                <CommonSeriesSettings
                    type="stepline"
                    argumentField="date"
                    ignoreEmptyPoints
                >
                    <Point visible={true} />
                </CommonSeriesSettings>
                {props.digiExpressions.map(function (item) {
                    return (
                        <Series
                            visible={item.visible}
                            key={item.value}
                            valueField={item.value}
                            name={item.name}
                            color={item.color}
                        />
                    )
                })}
                <ValueAxis placeholderSize={70}></ValueAxis>
                <ArgumentAxis argumentType="datetime">
                    <Label customizeText={dateFormatter}>
                        <Format type="decimal" />
                    </Label>
                </ArgumentAxis>
                <ScrollBar visible={true} />
                <ZoomAndPan argumentAxis="both" valueAxis="both" />
                <Tooltip enabled={true} customizeTooltip={customizeTooltip} />
                <Legend
                    verticalAlignment="bottom"
                    horizontalAlignment="center"
                />
                <Export enabled={true} />
                <Margin right={50} />
            </Chart>
        </div>
    )
}
