import DateFnsUtils from "@date-io/date-fns"
import { TextField } from "@material-ui/core"
import CircularProgress from "@material-ui/core/CircularProgress"
import FormControl from "@material-ui/core/FormControl"
import IconButton from "@material-ui/core/IconButton"
import InputLabel from "@material-ui/core/InputLabel"
import MenuItem from "@material-ui/core/MenuItem"
import Select from "@material-ui/core/Select"
import { createStyles, makeStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import RefreshIcon from "@material-ui/icons/Refresh"
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import CommonModal from "../../components/modals/CommonModal"
import TopNavSecondary from "../../components/TopNavSecondary"
import { setDate, setUnit as setUnitID } from "../../context/alert"
import { setSettings } from "../../context/graphs"
import {EXPRESSION, useEntries, useExpressions} from "../../utils/api/expressions";
import {UNIT_ALL_ID} from "../../utils/api/alarms";
import {useUnits} from "../../utils/api/units";
import {sortByNameComparator} from "../../utils/functions";
import FirstLastTable from "./tables/DifferencesTable";

const useStyles = makeStyles(() =>
    createStyles({
        formControl: {
            width: "14rem",
        },
    })
)

export default function Difference() {
    const classes = useStyles()
    const dateFrom = useSelector((state) => state.dateFrom)
    const dateTo = useSelector((state) => state.dateTo)
    const unitID = useSelector((state) => state.unit)
    const graphSettings = useSelector((state) => state.graphSettings)

    const dispatch = useDispatch()
    const [selectedDateTo, setSelectedDateTo] = useState(dateTo)
    const [selectedDateFrom, setSelectedDateFrom] = useState(dateFrom)
    const [unit, setUnit] = useState(unitID === UNIT_ALL_ID ? undefined : unitID )
    const [units, setUnits] = useState([])

    const [pulseExpressions, setPulseExpressions] = useState([])
    const [pulseData, setPulseData] = useState([])
    const [settingsModal, setSettingsModal] = useState(false)
    const [decimals, setDecimals] = useState(graphSettings?.decimals)
    const [isLoading, setLoading] = useState(false)

    useEffect(() => {
        setUnit(unitID === UNIT_ALL_ID ? undefined : unitID)
    },[unitID])


    const pulseExpressionsRequest = useExpressions(unit, EXPRESSION.PULSE)
    const entriesRequest = useEntries(unit, selectedDateFrom, selectedDateTo)

    useUnits((res)=>{
        setUnits(res.data.results.sort(sortByNameComparator))
        if(unitID !== UNIT_ALL_ID){
            setUnit(unitID)
        }
        else{
            dispatch(setUnitID(res.data.results[0]))
        }
    })

    useEffect(()=>{
        setLoading(
            pulseExpressionsRequest.isLoading &&
            entriesRequest.isLoading
        )
    },[
        pulseExpressionsRequest.isLoading,
        entriesRequest.isLoading
        ]
    )

    useEffect(()=>{

        if(
            pulseExpressionsRequest.isSuccess && entriesRequest.isSuccess
        ){
            setPulseExpressions(
                pulseExpressionsRequest.data.data.results
                    .map((exp) => {
                    let expr = pulseExpressions.find(
                        (item) => item.id === exp.id
                    )
                    return {
                        id: exp.id,
                        value: exp.id,
                        name: `${exp.name} [${exp.physical_unit}]`,
                        visible: expr ? expr.visible : false,
                    }
                })
            )
            setPulseData(
                entriesRequest.data.data.results.map((entry) => {
                    return {
                        ...convertObjNumber(entry.pulse_expressions),
                        date: entry.date,
                    }
                })
            )

        }
        // eslint-disable-next-line
    },[
        pulseExpressionsRequest.data,
        entriesRequest.data
    ])

    const handleRefresh = () => {
            pulseExpressionsRequest.refetch()
            entriesRequest.refetch()
    }

    const convertObjNumber = (obj) => {
        const res = {}
        for (const prop in obj) {
            const parsed = parseFloat(
                parseFloat(obj[prop], 10).toFixed(graphSettings.decimals)
            )
            res[prop] = isNaN(parsed) ? obj[prop] : parsed
        }
        return res
    }


    const handleChange = (event) => {
        setUnit(event.target.value)
        dispatch(setUnitID(event.target.value))
    }
    const handleDateToChange = (date) => {
        setSelectedDateTo(date)
        dispatch(setDate(selectedDateFrom, date))
    }
    const handleDateFromChange = (date) => {
        setSelectedDateFrom(date)
        dispatch(setDate(date, selectedDateTo))
    }

    if (!isLoading && units.length === 0) {
        return <Typography variant="h2">No units</Typography>
    }

    return (
        <>
            <CommonModal
                title="Graph settings"
                submitButtonLabel="Set"
                open={settingsModal}
                handleClose={() => setSettingsModal(false)}
                handleSubmit={() => {
                    dispatch(setSettings({ decimals: decimals }))
                    setSettingsModal(false)
                }}
                dialogProps={{
                    fullWidth: false,
                }}
            >
                <TextField
                    label="Decimal places"
                    type="number"
                    value={decimals}
                    onChange={(e) => {
                        if (e.target.value < 0) {
                            setDecimals(0)
                        }
                        else {
                            setDecimals(e.target.value)
                        }

                    }}
                />
            </CommonModal>
            <TopNavSecondary
                heading={"Difference"}
                handleSettings={() => {
                    setSettingsModal(true)
                }}
                buttons={[
                    !isLoading ? (
                        <IconButton
                            aria-label="refresh"
                            onClick={handleRefresh}
                        >
                            <RefreshIcon />
                        </IconButton>
                    ) : (
                        <CircularProgress />
                    ),
                    <FormControl className={classes.formControl}>
                        <InputLabel id="FormControl demo-simple-select-label">
                            Unit
                        </InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={unit}
                            onChange={handleChange}
                        >
                            {units.map((item, index) => (
                                <MenuItem key={index} value={item.id}>
                                    {item.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>,
                    <MuiPickersUtilsProvider
                        utils={DateFnsUtils}
                        className={classes.input}
                    >
                        <KeyboardDatePicker
                            disableToolbar
                            variant="inline"
                            format="dd/MM/yyyy"
                            label="From"
                            value={selectedDateFrom}
                            onChange={handleDateFromChange}
                            KeyboardButtonProps={{
                                "aria-label": "change date",
                            }}
                            InputProps={{ readOnly: true }}
                        />
                    </MuiPickersUtilsProvider>,
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            disableToolbar
                            variant="inline"
                            format="dd/MM/yyyy"
                            label="To"
                            value={selectedDateTo}
                            onChange={handleDateToChange}
                            KeyboardButtonProps={{
                                "aria-label": "change date",
                            }}
                            InputProps={{ readOnly: true }}
                        />
                    </MuiPickersUtilsProvider>,
                ]}
            />

            <FirstLastTable
                pulseData={pulseData}
                pulseExpressions={pulseExpressions}
                name={"Differences"}
            ></FirstLastTable>

        </>
    )
}
