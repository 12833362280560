import Snackbar from "@material-ui/core/Snackbar"
import MuiAlert from "@material-ui/lab/Alert"
import React, {useEffect} from "react"
import {connect} from "react-redux"
import {clearAlert} from "../context/alert"
import {makeStyles} from "@material-ui/core/styles"

const useStyles = makeStyles((theme) => ({
    pending: {
        backgroundColor: "#4a4a4a",
    },
}))

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />
}
const SnackBar = ({alert}) => {
    const classes = useStyles()

    const [open, setOpen] = React.useState(alert ? true : false)
    const [autoHideDuration, setAutoHideDuration] = React.useState(6000)
    const [icon, setIcon] = React.useState(null)
    const [pending, setPending] = React.useState(null)
    // Use side effect when the alert prop changes
    useEffect(() => {
        if (alert) {
            setOpen(true)
            setAutoHideDuration("autoHideDuration" in alert ? alert["autoHideDuration"] : 6000)
            setIcon("icon" in alert ? alert["icon"] : null)
            setPending("pending" in alert ? alert["pending"] : null)
        }
        else {
            clearAlert()
            setOpen(false)
        }
    }, [alert])

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return
        }
        clearAlert()
        setOpen(false)
    }

    return (
        <Snackbar open={open} autoHideDuration={autoHideDuration} onClose={handleClose}>
            <Alert
                onClose={handleClose}
                severity={alert ? alert["severity"] : ""}
                icon={icon ? icon : null}
                className={pending ? classes.pending : null}
            >
                {alert ? alert["message"] : ""}
            </Alert>
        </Snackbar>
    )
}

const mapStateToProps = ({alert}) => ({
    alert,
})

export default connect(mapStateToProps)(SnackBar)
