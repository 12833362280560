import {
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
} from "@material-ui/core"
import Button from "@material-ui/core/Button"
import Checkbox from "@material-ui/core/Checkbox"
import Grid from "@material-ui/core/Grid"
import {makeStyles} from "@material-ui/core/styles"
import TextField from "@material-ui/core/TextField"
import React, {useEffect, useState} from "react"
import {useDispatch} from "react-redux"
import {setAlert} from "../../../context/alert"
import {useAxios} from "../../../utils/request"

const useStyles = makeStyles((theme) => ({
    form: {
        "& > *": {
            margin: theme.spacing(1),
        },
        height: "15rem",
        width: "20rem",
    },
    grid: {
        marginTop: "1rem",
    },
    list: {
        width: 300,
        height: 200,
        backgroundColor: theme.palette.background.paper,
        overflow: "auto",
    },
}))

export default function AddUser(props) {
    const classes = useStyles()
    const dispatch = useDispatch()
    const [name, setName] = useState(props.edit ? props.edit.username : "")
    const [password, setPassword] = useState()
    const [adminCheck, setAdminCheck] = useState(
        props.edit ? props.edit.is_superuser : false
    )
    const [viewerCheck, setViewerCheck] = useState(
        props.edit ? props.edit.is_viewer : false
    )
    const [checkName, setCheckName] = useState(false)

    const getUserRequest = useAxios(
        "getUsers",
        {
            url: `/users/`,
            method: "get",
        },
        {enabled: true, refetchInterval: 1000 * 10},
        () => {
            if (getUserRequest.data) {
                let check1 = getUserRequest.data.data.results.find(
                    (item) => item.username === name
                )
                let check2 = props.edit ? !(name === props.edit.username) : true
                setCheckName(check1 && check2)
            }
        }
    )

    const submitUserRequest = useAxios(
        "submitUser",
        {
            url: `/users/${props.edit ? props.edit.id + "/" : ""}`,
            method: props.edit ? "PUT" : "POST",
            data: {
                username: name,
                password: password,
                is_superuser: adminCheck,
                is_viewer: viewerCheck
            },
        },
        {},
        () => {
            dispatch(
                setAlert({
                    status: "success",
                    message: "New user was created.",
                })
            )
            props.handleClose()
        }
    )

    const handleSubmit = (e) => {
        e.preventDefault()
        submitUserRequest.refetch()
    }

    useEffect(() => {
        if (viewerCheck) {
            setAdminCheck(false);
        }
    }, [viewerCheck])

    useEffect(() => {
        if (adminCheck) {
            setViewerCheck(false);
        }
    }, [adminCheck])

    return (
        <div>
            <Dialog
                fullWidth={true}
                maxWidth={"sm"}
                open={props.open}
                onClose={props.handleClose}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogTitle id="scroll-dialog-title">
                    {(props.edit ? "Edit " : "Add ") + " user"}
                </DialogTitle>
                <DialogContent dividers={true}>
                    <div>
                        <Grid
                            container
                            direction="row"
                            justify="center"
                            alignItems="center"
                        >
                            <Grid item>
                                <form
                                    className={classes.form}
                                    id="add_expression_form"
                                    onSubmit={handleSubmit}
                                >
                                    <TextField
                                        label="Name:"
                                        variant="standard"
                                        required
                                        fullWidth
                                        size="small"
                                        error={checkName}
                                        helperText={
                                            checkName
                                                ? "Name already exists!"
                                                : ""
                                        }
                                        onChange={(e) => {
                                            setName(e.target.value)
                                        }}
                                        value={name}
                                    />
                                    <TextField
                                        label="Password:"
                                        variant="standard"
                                        required
                                        fullWidth
                                        size="small"
                                        onChange={(e) =>
                                            setPassword(e.target.value)
                                        }
                                        value={password}
                                    />

                                    {!(props.edit && props.edit.id === 1) && (
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={adminCheck}
                                                    onChange={(event) => {
                                                        setAdminCheck(
                                                            event.target.checked
                                                        )
                                                    }}
                                                    inputProps={{
                                                        "aria-label":
                                                            "primary checkbox",
                                                    }}
                                                />
                                            }
                                            label="Is admin?"
                                        />
                                    )}
                                    {!(props.edit && props.edit.id === 1) && (
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={viewerCheck}
                                                onChange={(event) => {
                                                    setViewerCheck(
                                                        event.target.checked
                                                    )
                                                }}
                                                inputProps={{
                                                    "aria-label":
                                                        "primary checkbox",
                                                }}
                                            />
                                        }
                                        label="Is viewer?"
                                    />)}
                                </form>
                            </Grid>
                        </Grid>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Grid
                        container
                        justify="flex-end"
                        spacing={2}
                        className={classes.grid}
                    >
                        <Grid item>
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={props.handleClose}
                            >
                                Cancel
                            </Button>
                        </Grid>
                        <Grid item>
                            {submitUserRequest.isLoading ? (
                                <CircularProgress />
                            ) : (
                                <Button
                                    type="submit"
                                    form="add_expression_form"
                                    variant="contained"
                                    color="primary"
                                >
                                    {(props.edit ? "Edit " : "Add ") + " user"}
                                </Button>
                            )}
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
        </div>
    )
}
