import {Button} from "@material-ui/core"
import {makeStyles} from "@material-ui/core/styles"
import {default as CheckIcon} from "@material-ui/icons/Check"
import NotInterestedIcon from "@material-ui/icons/NotInterested"
import MaterialTable from "material-table"
import {default as React, useState} from "react"
import {useSelector} from "react-redux"
import AddUser from "./modals/AddUser"
import DeleteModal from "../../components/modals/DeleteModal"
import EditUnitsPerm from "./modals/EditUnitPermissions"
import TopNavSecondary from "../../components/TopNavSecondary"
import {tableIcons} from "../../components/tables/tableIcons"
import {useAxios} from "../../utils/request"

// const request = require('../index').default

const useStyles = makeStyles((theme) => ({
    table: {
        padding: "0px",
    },
}))

export default function Users() {
    const classes = useStyles()
    const url = useSelector((state) => state.baseUrl)
    const userId = useSelector((state) => state.id)
    const [open, setOpen] = React.useState(false)
    const [unitPermModal, setUnitPermModal] = useState(false)
    const [deleteUserModal, setDeleteUserModal] = useState(false)
    const [editUser, setEditUser] = useState(false)
    const [users, setUsers] = React.useState([])
    const [user, setUser] = useState({})

    const getUserRequest = useAxios(
        "getUsers",
        {
            url: `/users/`,
            method: "get",
        },
        {enabled: true, refetchInterval: 1000 * 60 * 5},
        (res) => {
            setUsers(res.data.results)
        }
    )

    const handleOpen = () => {
        setEditUser(undefined)
        setOpen(true)
    }

    return (
        <>
            <TopNavSecondary
                heading={"Users"}
                buttons={[
                    <Button
                        className="btn primary"
                        variant="contained"
                        color="primary"
                        onClick={handleOpen}
                    >
                        ADD NEW USER
                    </Button>,
                ]}
            ></TopNavSecondary>

            {open && (
                <AddUser
                    open={open}
                    handleClose={() => {
                        setOpen(false)
                        getUserRequest.refetch()
                    }}
                    unit={undefined}
                    edit={editUser}
                />
            )}

            {unitPermModal && (
                <EditUnitsPerm
                    open={unitPermModal}
                    handleClose={() => {
                        setUnitPermModal(false)
                        getUserRequest.refetch()
                    }}
                    user={user}
                />
            )}
            {deleteUserModal && (
                <DeleteModal
                    open={deleteUserModal}
                    handleClose={() => {
                        setDeleteUserModal(false)
                        getUserRequest.refetch()
                    }}
                    url={url + `/users/${editUser.id}/`}
                    deleteText={`DELETE USER`}
                    header={`Delete user ${editUser.username}?`}
                    alertText={"User was deleted"}
                />
            )}
            <MaterialTable
                options={{
                    actionsCellStyle: {justifyContent: "center"},
                    pageSize: 10,
                    actionsColumnIndex: -1,
                    search: false,
                }}
                className={classes.table}
                icons={tableIcons}
                columns={[
                    {title: "Id", field: "id"},
                    {title: "Name", field: "username"},
                    {
                        title: "Admin",
                        field: "is_superuser",
                        render: (rowData) =>
                            !!rowData.is_superuser ? (
                                <CheckIcon />
                            ) : (
                                <NotInterestedIcon />
                            ),
                    },
                    {
                        title: "Viewer",
                        field: "is_viewer",
                        render: (rowData) =>
                            !!rowData.is_viewer ? (
                                <CheckIcon />
                            ) : (
                                <NotInterestedIcon />
                            ),
                    },
                ]}
                data={users}
                title={"Users"}
                actions={[
                    (row) => ({
                        icon: tableIcons.Delete,
                        tooltip: "Delete user",
                        hidden: row.is_master_admin,
                        onClick: (event, rowData) => {
                            setEditUser(rowData)
                            setDeleteUserModal(true)
                            return 0
                        },
                    }),
                    (row) => ({
                        icon: tableIcons.Edit,
                        tooltip: "Edit user",
                        hidden: row.is_master_admin && !(userId === row.id),
                        onClick: (event, rowData) => {
                            setEditUser(rowData)
                            setOpen(true)
                            return 0
                        },
                    }),
                    (row) => ({
                        icon: tableIcons.UnitIcon,
                        tooltip: "Edit units permissions",
                        hidden: row.is_superuser,
                        onClick: (event, rowData) => {
                            setUnitPermModal(true)
                            setUser(rowData)
                            return 0
                        },
                    }),
                ]}
            />
        </>
    )
}
