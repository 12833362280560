// alarms API
import {request, useAxios} from "../request";
import {useMutation, useQueries} from "react-query";

export const UNIT_ALL_ID = -1

export const ALARM_TYPES = {
    analogalarm: {
        name: 'Analog alarm',
        url: '/analog_alarms',
        key: 'analog_alarms',
        type: 'analogalarm'
    },
    analogintervalalarm: {
        name: 'Analog interval alarm',
        url: '/analog_interval_alarms',
        key: 'analog_interval_alarms',
        type: 'analogintervalalarm'
    },
    digitalalarm: {
        name: 'Digital alarm',
        url: '/digital_alarms',
        key: 'digital_alarms',
        type: 'digitalalarm'
    },
    pulsealarmcombined: {
        name: 'Pulse combined alarm',
        url: '/pulse_combined_alarms',
        key: 'pulse_combined_alarms',
        type: 'pulsealarmcombined'
    },
    pulsealarm: {
        name: 'Pulse alarm',
        url: '/pulse_alarms',
        key: 'pulse_alarms',
        type: 'pulsealarm'
    },
    missingalarm: {
        name: 'Missing alarm',
        url: '/alarms_missing',
        key: 'alarms_missing',
        type: 'alarmsmissing'
    },
    materialstockalarm:{
        name: 'Material stock alarm',
        url: '/material_stock_alarms',
        key: 'material_stock',
        type: 'materialstockalarm'
    }
}

export const ACTION_TYPE = {
    EMA: 'EMA', //EMAIL_ACTION
    ENA: 'ENA'  //ENABLE_ACTION
}

export const checkIfUnitAll = (unit) => unit === UNIT_ALL_ID ? undefined : unit

export const usePatchAlarm = () => useMutation( ({id, alarmType, data}) => {
    return request({
        url: `${alarmType.url}/${id}/`,
        method: "PATCH",
        data: {...data}
    })
})

export const useAlarmEmails = () => useMutation( ({alarmType, alarmData, emailActionData, edit=false, enableAlarms=[], actionType=ACTION_TYPE.EMA}) => {
    let HTTP_METHOD = edit ? "PATCH" : "POST"
    if( actionType === ACTION_TYPE.ENA)
    {
        return Promise.all(
            enableAlarms.map(
                (alarm) => {
                    return request({
                        url: `/enable_alarm_actions/`,
                        method: "POST",
                        data: {
                            alarm_content_type: alarm.contentTypeId,
                            alarm_id: alarm.id,
                            name: `ENA-${alarm.id}-${alarm.contentTypeId}`
                        }
                    })
                }
            )
        ).then(res => {
            let actionIds = res.map(action => action.data.id)
            return request({
                url: edit ?
                    `${alarmType.url}/${edit.id}/` :
                    `${alarmType.url}/`,
                method: HTTP_METHOD,
                data: {
                    ...alarmData,

                    enable_alarm_actions: actionIds,
                    action_type: ACTION_TYPE.ENA
                }
            }).then((res)=>{
                //delete old actions if there were any
                if(edit?.enable_alarm_actions){
                    return Promise.all(
                        edit.enable_alarm_actions.map(
                            (actionId) => {
                                return request({
                                    url: `/enable_alarm_actions/${actionId}`,
                                    method: "DELETE",
                                })
                            }
                        )
                    ).then(() => {
                        return Promise.resolve(res)
                    })
                }
                else{
                    return Promise.resolve(res)
                }
            })
        })
    }
    else if (emailActionData.emails === "" || emailActionData.emails === undefined){
        return request({
            url: edit ?
                `${alarmType.url}/${edit.id}/` :
                `${alarmType.url}/`,
            method: HTTP_METHOD,
            data: {...alarmData, email_action: ""}
        })
    }
    else{

        return request({
            url: edit?.email_action ?
                `/send_email_actions/${edit.email_action}/` :
                `/send_email_actions/`,
            method: edit ? ( edit?.email_action ? "PATCH" : "POST" )  : HTTP_METHOD,
            data: {
                name: `EMA-${alarmData.name}`,
                emails: emailActionData.emails.toString().replace(/ /g, "").split(",")
            }
        }).then((res)=>{
            let actionId = res.data.id
            return request({
                url: edit ?
                    `${alarmType.url}/${edit.id}/` :
                    `${alarmType.url}/`,
                method: HTTP_METHOD,
                data: {...alarmData, email_action: actionId}
            })
        })
    }
})

export function useAlarmContentType() {
    return useAxios(
        ["alarms_content_types"],
        {
            url: "/alarms_content_types/",
            method: "GET",
        },
        {
            enabled: true,
            cacheTime: 15 * 60 * 1000,
            refetchOnMount: false,
            refetchOnWindowFocus: false
        },
    )
}

export function useAlarmLatestHistory(unitId, triggered) {
    return useAxios(
        ["get_latest_history", unitId, triggered],
        {
            url: '/alarm_history_status/get_latest_history/',
            method: "GET",
            params:{
                unit_id: checkIfUnitAll(unitId),
                triggered: triggered
            }
        },
        {
            enabled: true,
            select: (data) => {
                return data.data.filter((i) => !( i.acknowledged && !i.is_actual))
            }
        },
    )
}

export function getAlarmHistory(alarmContentType, alarmId, query){
    return request(
        {
            url: `/alarm_history_status/`,
            method: "GET",
            params: {
                alarm_content_type: alarmContentType,
                alarm_id: alarmId,
                triggered: true,
                ordering:
                    query.orderBy &&
                    (query.orderDirection === "desc"
                        ? "-"
                        : "") + query.orderBy?.field,
                limit: query.pageSize,
                offset: query.pageSize * query.page,
            }
        }
    )
}

export const useAcknowledgeAlarm = () => useMutation( ({id}) => {
    return request({
        url: `/alarm_history_status/${id}/`,
        method: "PATCH",
        data: {
            acknowledged: true
        }
    })
})

export const useAcknowledgeAll = () => useMutation( ({alarmContentType, alarmId}) => {
    return request({
        url: `/alarm_history_status/acknowledge_all/`,
        method: "POST",
        data: {
            alarm_content_type: alarmContentType,
            alarm_id: alarmId,
        }
    })
})

export const useAcknowledgeAllTypes = () => useMutation( ({alarms}) => {
    return request({
        url: `/alarm_history_status/acknowledge_all_types/`,
        method: "POST",
        data: {
            alarms: alarms,
        }
    })
})

export function useAnalogDigitalAlarms(
    unit = undefined,
    enabled = undefined,
    actionType=ACTION_TYPE.EMA,
    digital=false
) {
    return  useAxios(
        [(digital ? "digital_alarms" : "analog_alarms"), unit, enabled, actionType],
        {
            url: (digital ? "/digital_alarms/" : "/analog_alarms/"),
            params: {
                unit: checkIfUnitAll(unit),
                enabled: enabled,
                action_type: actionType
            },
            method: "GET",
        },
        {enabled: true},
    )
}


export function useAnalogAlarms(
    unit = undefined,
    enabled = undefined,
    actionType=ACTION_TYPE.EMA
    ) {
    return  useAxios(
        ["analog_alarms", unit, enabled, actionType],
        {
            url: "/analog_alarms/",
            params: {
                unit: checkIfUnitAll(unit),
                enabled: enabled,
                action_type: actionType
            },
            method: "GET",
        },
        {enabled: true},
    )
}


export function useDigitalAlarms(
    unit = undefined,
    enabled = undefined,
    actionType=ACTION_TYPE.EMA
) {
    return useAxios(
        ["digital_alarms", unit, enabled, actionType],
        {
            url: "/digital_alarms/",
            params: {
                unit: checkIfUnitAll(unit),
                enabled: enabled,
                action_type: actionType
            },
            method: "GET",
        },
        {enabled: true},
    )
}


export function usePulseCombinedAlarms(
    unit = undefined,
    enabled = undefined,
    actionType=ACTION_TYPE.EMA
) {
    return useAxios(
        ["pulse_combined_alarms", unit, enabled, actionType],
        {
            url: `/pulse_combined_alarms/`,
            params: {
                unit: checkIfUnitAll(unit),
                enabled: enabled,
                action_type: actionType
            },
            method: "GET",
        },
        {enabled: true},
    )
}


export function useAnalogIntervalAlarms(
    unit = undefined,
    enabled = undefined,
    actionType=ACTION_TYPE.EMA
) {
    return useAxios(
        ["analog_interval_alarms", unit, enabled, actionType],
        {
            url: `/analog_interval_alarms/`,
            params: {
                unit: checkIfUnitAll(unit),
                enabled: enabled,
                action_type: actionType
            },
            method: "GET",
        },
        {enabled: true},
    )
}

export function usePulseAlarms(
    unit = undefined,
    enabled = undefined,
    actionType=ACTION_TYPE.EMA
) {
    return useAxios(
        ["pulse_alarms", unit, enabled, actionType],
        {
            url: `/pulse_alarms/`,
            params: {
                unit: checkIfUnitAll(unit),
                enabled: enabled,
                action_type: actionType
            },
            method: "GET",
        },
        {enabled: true},
    )
}


export function useMaterialStockAlarms(
    unit = undefined,
    enabled = undefined,
    actionType=ACTION_TYPE.EMA
) {
    return  useAxios(
        [ALARM_TYPES.materialstockalarm.key, unit, enabled, actionType],
        {
            url: `${ALARM_TYPES.materialstockalarm.url}/`,
            params: {
                unit: checkIfUnitAll(unit),
                enabled: enabled,
                action_type: actionType
            },
            method: "GET",
        },
        {enabled: true},
    )
}

export function useAllAlarms(
    unit=undefined,
    enabled=undefined,
    actionType=ACTION_TYPE.EMA
){


    return useQueries(
        [
            {
                queryKey: ["digital_alarms", unit, enabled, actionType],
                queryFn: () => request( {
                    url: "/digital_alarms/",
                    params: {
                        unit: checkIfUnitAll(unit),
                        enabled: enabled,
                        action_type: actionType
                    },
                    method: "GET",
                }),
                select: (data) => {
                    return {
                        alarmType: ALARM_TYPES.digitalalarm,
                        results: data.data.results.map(item => ({...item, alarmType: ALARM_TYPES.digitalalarm}))
                    }
                }
            },
            {
                queryKey:["analog_alarms", unit, enabled, actionType],
                queryFn: () => request({
                    url: "/analog_alarms/",
                    params: {
                        unit: checkIfUnitAll(unit),
                        enabled: enabled,
                        action_type: actionType
                    },
                    method: "GET",
                }),
                select: (data) => {
                    return {
                        alarmType: ALARM_TYPES.analogalarm,
                        results: data.data.results.map(item => ({...item, alarmType: ALARM_TYPES.analogalarm}))
                    }
                }
            },
            {
                queryKey: ["analog_interval_alarms", unit, enabled, actionType],
                queryFn: () => request({
                    url: `/analog_interval_alarms/`,
                    params: {
                        unit: checkIfUnitAll(unit),
                        enabled: enabled,
                        action_type: actionType
                    },
                    method: "GET",
                }),
                select: (data) => {
                    return {
                        alarmType: ALARM_TYPES.analogintervalalarm,
                        results: data.data.results.map(item => ({...item, alarmType: ALARM_TYPES.analogintervalalarm}))
                    }
                }
            },
            {
                queryKey: ["pulse_combined_alarms", unit, enabled, actionType],
                queryFn: () => request({
                    url: `/pulse_combined_alarms/`,
                    params: {
                        unit: checkIfUnitAll(unit),
                        enabled: enabled,
                        action_type: actionType
                    },
                    method: "GET",
                }),
                select: (data) => {
                    return {
                        alarmType: ALARM_TYPES.pulsealarmcombined,
                        results: data.data.results.map(item => ({...item, alarmType: ALARM_TYPES.pulsealarmcombined}))
                    }
                }
            },
            {
                queryKey: ["pulse_alarms", unit, enabled, actionType],
                queryFn: () => request({
                    url: `/pulse_alarms/`,
                    params: {
                        unit: checkIfUnitAll(unit),
                        enabled: enabled,
                        action_type: actionType
                    },
                    method: "GET",
                }),
                select: (data) => {
                    return {
                        alarmType: ALARM_TYPES.pulsealarm,
                        results: data.data.results.map(item => ({...item, alarmType: ALARM_TYPES.pulsealarm}))
                    }
                }
            },
            {
                queryKey: [ALARM_TYPES.materialstockalarm.key, unit, enabled, actionType],
                queryFn: () => request({
                    url: `${ALARM_TYPES.materialstockalarm.url}/`,
                    params: {
                        unit: checkIfUnitAll(unit),
                        enabled: enabled,
                        action_type: actionType
                    },
                    method: "GET",
                }),
                select: (data) => {
                    return {
                        alarmType: ALARM_TYPES.materialstockalarm,
                        results: data.data.results.map(item => ({...item, alarmType: ALARM_TYPES.materialstockalarm}))
                    }
                }
            },
        ]
    )
}
