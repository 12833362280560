import {makeStyles} from "@material-ui/core/styles"
import MaterialTable from "material-table"
import React, {useEffect, useState} from "react"
import DeleteModal from "../../../../components/modals/DeleteModal"
import {tableIcons} from "../../../../components/tables/tableIcons"
import {renameType} from "../../../../utils/enumMapping"
import {ACTION_TYPE, UNIT_ALL_ID, useMaterialStockAlarms} from "../../../../utils/api/alarms";
import {MaterialStockAlarmModal} from "../modals/MaterialStockAlarmModal";

const useStyles = makeStyles(() => ({
    table: {
        padding: "0px",
    },
}))

export default function MaterialStockAlarmTable({id, actionType}) {
    const classes = useStyles()
    const [data, setData] = useState([])
    const [modalAdd, setModalAdd] = useState(false)
    const [modalDelete, setModalDelete] = useState(false)
    const [editModal, setEditModal] = useState(false)

    const getMaterialStockAlarms = useMaterialStockAlarms(id, undefined, actionType)

    useEffect(()=>{
        if(getMaterialStockAlarms.isSuccess) {
            setData(getMaterialStockAlarms.data?.data.results)
        }
        // eslint-disable-next-line
    }, [getMaterialStockAlarms.data])


    useEffect(
        (_) => {
            getMaterialStockAlarms.refetch()
        },
        // eslint-disable-next-line
        [modalAdd, modalDelete]
    )

    const deleteRow = (event, rowData) => {
        setModalDelete(rowData)
    }

    return (
        <>
            {modalAdd && (
                <MaterialStockAlarmModal
                    open={modalAdd}
                    handleClose={() => {
                        setModalAdd(false)
                        setEditModal(false)
                    }}
                    unit={id}
                    edit={editModal}
                    actionType={actionType}
                />
            )}
            <DeleteModal
                open={!!modalDelete}
                handleClose={() => {
                    setModalDelete(false)
                }}
                url={`/material_stock_alarms/${modalDelete.id}/`}
                deleteText="DELETE MATERIAL STOCK ALARM"
                header={`Delete material stock alarm ${modalDelete.name}?`}
                alertText={"Materialstock alarm was deleted"}
            />

            <MaterialTable
                className={classes.table}
                icons={tableIcons}
                columns={[
                    {title: "Name", field: "name"},
                    {
                        title: "Trigger type",
                        field: "trigger_type",
                        render: (rowData) => renameType(rowData.trigger_type),
                    },
                    {
                        title: "Silenced",
                        field: "enabled",
                        render: (rowData) => rowData.enabled ? 'no' : 'yes'
                    },
                    {title: "Expression", field: "expression_name"},
                    {
                        title: "E-mails",
                        hidden: actionType !== ACTION_TYPE.EMA,
                        render: (rowData) =>
                            rowData.email_action_detail?.emails
                                .reduce((acc, item) => acc + ", " + item, "")
                                .substring(1),
                    },
                    {
                        title: "Reactivate alarms",
                        hidden: actionType !== ACTION_TYPE.ENA,
                        render: (rowData) =>
                            rowData.enable_alarm_actions_details
                                .reduce((acc, item) => acc + ", " + item.alarm_name, "")
                                .substring(1),
                    },
                    {title: "Trigger value", field: "value"},
                ]}
                data={data}
                title="Material stock alarm"
                isLoading={getMaterialStockAlarms.isLoading}
                actions={[
                    {
                        icon: tableIcons.Add,
                        tooltip: "Add material stock alarm",
                        isFreeAction: true,
                        disabled: id === UNIT_ALL_ID,
                        onClick: () => setModalAdd(true),
                    },
                    {
                        icon: tableIcons.Delete,
                        tooltip: "Delete alarm",
                        onClick: deleteRow,
                    },
                    {
                        icon: tableIcons.Edit,
                        tooltip: "Edit alarm",
                        disabled: id === UNIT_ALL_ID,
                        onClick: (event, rowData) => {
                            setEditModal(rowData)
                            setModalAdd(true)
                        },
                    },
                ]}
                options={{
                    pageSize: 10,
                    actionsColumnIndex: -1,
                    search: false,
                }}
            />
        </>
    )
}
