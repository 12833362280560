import {
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from "@material-ui/core"
import Button from "@material-ui/core/Button"
import Grid from "@material-ui/core/Grid"
import {makeStyles} from "@material-ui/core/styles"
import TextField from "@material-ui/core/TextField"
import React, {useState} from "react"
import {useDispatch} from "react-redux"
import {setAlert} from "../../../context/alert"
import {useAxios} from "../../../utils/request"

const useStyles = makeStyles((theme) => ({
    root: {
        "& > *": {
            margin: theme.spacing(1),
        },
        height: "10rem",
        width: "30rem",
        display: "flex",
        flexDirection: "column",
        justifyContent: "start",
    },
    grid: {
        marginTop: "1rem",
    },

    formControl: {
        marginTop: 10,
        marginRight: 10,
    },
    expression: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "start",
    },
}))

export default function MaterialStockModal(props) {
    const classes = useStyles()
    const dispatch = useDispatch()
    const [difference, setDifference] = useState("")
    const [note, setNote] = useState("")

    const handleSubmitRequest = useAxios(
        "addStock",
        {
            url: "material_stocks/manual_stock_update/",
            method: "POST",
            data: {
                expression: props.expression.id,
                stock_difference: Number(difference),
                note: note,
            },
        },
        {},
        () => {
            props.handleClose()
            dispatch(
                setAlert({
                    status: "success",
                    message: "Material stock modified!",
                })
            )
        },
        () => {
            props.handleClose()
            dispatch(
                setAlert({
                    severity: "error",
                    message: "Something went wrong.",
                })
            )
        }
    )

    const handleSubmit = (e) => {
        e.preventDefault()
        handleSubmitRequest.refetch()
    }

    return (
        <Dialog
            open={props.open}
            onClose={props.handleClose}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
        >
            <DialogTitle id="scroll-dialog-title">
                {`Editing material stock for ${props?.expression.name}`}
            </DialogTitle>
            <DialogContent dividers={true}>
                <form
                    className={classes.root}
                    id="add_expression_form"
                    onSubmit={handleSubmit}
                >
                    <TextField
                        label="Difference"
                        placeholder={"+/- 100"}
                        variant="standard"
                        required
                        fullWidth
                        size="small"
                        className={classes.formControl}
                        onChange={(e) => setDifference(e.target.value)}
                        value={difference}
                    />

                    <TextField
                        label="Note"
                        placeholder={"George added"}
                        variant="standard"
                        required
                        fullWidth
                        size="small"
                        className={classes.formControl}
                        onChange={(e) => setNote(e.target.value)}
                        value={note}
                    />
                </form>
            </DialogContent>
            <DialogActions>
                <Grid
                    container
                    justify="flex-end"
                    spacing={2}
                    className={classes.grid}
                >
                    <Grid item>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={props.handleClose}
                        >
                            Cancel
                        </Button>
                    </Grid>
                    <Grid item>
                        {handleSubmitRequest.isLoading ? (
                            <CircularProgress />
                        ) : (
                            <Button
                                type="submit"
                                form="add_expression_form"
                                variant="contained"
                                color="primary"
                            >
                                Edit stock
                            </Button>
                        )}
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    )
}
