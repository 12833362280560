import axios from "axios"
import {BASE_URL} from "../config/config"
import storage from "./storage"
import {store} from "../index"
import {useState} from "react"
import {useQuery} from "react-query"
import jwt_decode from "jwt-decode"

// eslint-disable-next-line
const tokenExpires = (token) => {
    if (token) {
        let jwt = jwt_decode(token)
        let time = (new Date().getTime() + 1000 * 60) / 1000 // time now plus minute
        if (time < jwt.exp) {
            return false
        }
    }
    return true
}

export const request = axios.create({
    timeout: 20000,
    baseURL: BASE_URL,
})

// Add a request interceptor
request.interceptors.request.use(
    (config) => {
        let token = storage.getToken()
        if (token) {
            config.headers["Authorization"] = "Bearer " + (token || "")
        }
        config.headers["Content-Type"] =
            config.headers["Content-Type"] || "application/json"
        return config
    },
    (error) => {
        console.log("Request error")
        Promise.reject(error)
    }
)

//Add a response interceptor
request.interceptors.response.use(
    (response) => {
        return Promise.resolve(response)
    },
    async (error) => {
        const originalRequest = error.config
        console.log(`Error: ${error}`)

        if (!error.response) {
            console.log("Network Error")
            return Promise.reject({data: error.message})
        }

        if (!error.response.status) {
            console.log("Response error")
            return Promise.reject({...error.response, data: error.message})
        }

        if (
            error.response.status === 401 &&
            originalRequest.url === "/api/token/refresh/"
        ) {
            console.log("logout")
            store.dispatch({type: "LOGOUT"})
            return Promise.reject(error.response)
        }

        if (error.response.status === 401 && !originalRequest._retry) {
            console.log("Refreshing token")
            originalRequest._retry = true
            const refreshToken = storage.getRefreshToken()
            return axios
                .post(`${originalRequest.baseURL}/api/token/refresh/`, {
                    refresh: refreshToken,
                })
                .then((res) => {
                    if (res.status === 200) {
                        console.log("Token refreshed!")
                        storage.setToken(res.data.access)
                        axios.defaults.headers.common[
                            "Authorization"
                        ] = `Bearer ${res.data.access}`
                        const conf = {
                            ...originalRequest,
                            headers: {
                                Authorization: "Bearer " + res.data.access,
                            },
                        }
                        return axios(conf)
                    }
                })
                .catch((err) => {
                    console.log("Refresh token problem")
                    store.dispatch({type: "LOGOUT"})
                    return Promise.reject(error.response) //default error above, thrown for the first time
                })
        }
        return Promise.reject(error.response)
    }
)

export function useCustomAxios(axiosParams) {
    const [response, setResponse] = useState(undefined)
    const [error, setError] = useState("")
    const [loading, setloading] = useState(false)

    const fun = () => {
        setloading(true)
        request(axiosParams)
            .then((res) => {
                setResponse(res)
            })
            .catch((err) => {
                setError(err)
            })
            .finally(() => {
                setloading(false)
            })
    }

    const exec = () => {
        fun()
    }

    return {response, error, loading, exec}
}

export function useAxios(
    key,
    axiosParams,
    reactQueryProps = {},
    onSuccess = (res) => res,
    onError = (err) => err,
    onDone = () => {}
) {
    const props = useQuery(
        key,
        () =>
            request(axiosParams)
                .then((res) => {
                    onSuccess(res)
                    onDone()
                    return Promise.resolve(res)
                })
                .catch((err) => {
                    onError(err)
                    onDone()
                    return Promise.reject(err)
                }),

        {
            enabled: false,
            retry: false,
            cacheTime: 0,
            ...reactQueryProps,
        }
    )

    return props
}
