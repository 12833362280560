import {FormControl, InputLabel, ListSubheader, makeStyles, MenuItem, Select} from "@material-ui/core";
import CommonModal from "../../../components/modals/CommonModal";
import React, {useState} from "react";
import {
    ALARM_TYPES,
    useAnalogAlarms, useAnalogIntervalAlarms,
    useDigitalAlarms, useMaterialStockAlarms,
    usePatchAlarm, usePulseAlarms, usePulseCombinedAlarms
} from "../../../utils/api/alarms";
import {setAlert} from "../../../context/alert";
import {useDispatch} from "react-redux";

const useStyles = makeStyles(() => ({
    alarmsGroups:{
        width: 200
    }
}))

export function AddAlarmSilenceModal({open, handleClose, unitId}){

    const classes = useStyles()

    const getDigitalAlarmsRequest = useDigitalAlarms(unitId)
    const getAnalogAlarmsRequest = useAnalogAlarms(unitId)
    const getAnalogIntervalAlarmsRequest = useAnalogIntervalAlarms(unitId)
    const getPulseCombinedAlarmsRequest = usePulseCombinedAlarms(unitId)
    const getPulseAlarmsRequest = usePulseAlarms(unitId)
    const getMaterialStockAlarmsRequest = useMaterialStockAlarms(unitId)

    const dispatch = useDispatch()

    const [alarm, setAlarm] = useState('')

    const alarmPatch = usePatchAlarm()

    const handleChange = (e) => {
        setAlarm(e.target.value)
    }

    const handleSubmit = () => {
        alarmPatch.mutateAsync({
            id: alarm.id,
            alarmType: alarm.alarmType,
            data: {
                enabled: false
            }
        }).then(()=>{
            dispatch(
                setAlert({
                    severity: "success",
                    message:
                        "Alarm silenced",
                })
            )
            handleClose()
        }).catch(()=>{
            dispatch(
                setAlert({
                    severity: "error",
                    message:
                        "Alarm silencing failed",
                })
            )
        })


    }

    return (
        <CommonModal
            title="Silence alarm"
            submitButtonLabel="Set"
            open={open}
            handleClose={handleClose}
            handleSubmit={handleSubmit}
            loading={alarmPatch.isLoading}
        >
            <FormControl className={classes.alarmsGroups}>
                <InputLabel htmlFor="grouped-select">Alarms</InputLabel>
                <Select
                    id="grouped-select"
                    onChange={handleChange}
                    value={alarm}
                    renderValue={(value) => `${value.name}`}
                >
                    <ListSubheader>Digital alarms</ListSubheader>
                    {
                        getDigitalAlarmsRequest.isSuccess &&
                        getDigitalAlarmsRequest.data.data.results.map(
                        (item) => {
                            return <MenuItem value={
                                {
                                    alarmType: ALARM_TYPES.digitalalarm,
                                    ...item
                                }
                            }>{item.name}</MenuItem>
                        }
                    )}
                    <ListSubheader>Analog alarms</ListSubheader>
                    {
                        getAnalogAlarmsRequest.isSuccess &&
                        getAnalogAlarmsRequest.data.data.results.map(
                        (item) => {
                            return <MenuItem value={
                                {
                                    alarmType: ALARM_TYPES.analogalarm,
                                    ...item

                                }
                            }>{item.name}</MenuItem>
                        }
                    )}
                    <ListSubheader>Analog Interval alarms</ListSubheader>
                    {
                        getAnalogIntervalAlarmsRequest.isSuccess &&
                        getAnalogIntervalAlarmsRequest.data.data.results.map(
                            (item) => {
                                return <MenuItem value={
                                    {
                                        alarmType: ALARM_TYPES.analogintervalalarm,
                                        ...item

                                    }
                                }>{item.name}</MenuItem>
                            }
                        )}
                    <ListSubheader>Pulse combine alarms</ListSubheader>
                    {
                        getPulseCombinedAlarmsRequest.isSuccess &&
                        getPulseCombinedAlarmsRequest.data.data.results.map(
                        (item) => {
                            return <MenuItem value={
                                {
                                    alarmType: ALARM_TYPES.pulsealarmcombined,
                                    ...item
                                }
                            }>{item.name}</MenuItem>
                        }
                    )}
                    <ListSubheader>Pulse alarms</ListSubheader>
                    {
                        getPulseAlarmsRequest.isSuccess &&
                        getPulseAlarmsRequest.data.data.results.map(
                        (item) => {
                            return <MenuItem value={
                                {
                                    alarmType: ALARM_TYPES.pulsealarm,
                                    ...item
                                }
                            }>{item.name}</MenuItem>
                        }
                    )}
                    <ListSubheader>Materialstock alarms</ListSubheader>
                    {
                        getMaterialStockAlarmsRequest.isSuccess &&
                        getMaterialStockAlarmsRequest.data.data.results.map(
                            (item) => {
                                return <MenuItem value={
                                    {
                                        alarmType: ALARM_TYPES.materialstockalarm,
                                        ...item
                                    }
                                }>{item.name}</MenuItem>
                            }
                        )}
                </Select>
            </FormControl>
        </CommonModal>
    )
}