import {useAxios} from "../request";

export function useUnits(onSuccess=()=>{}) {
    return useAxios(
        "units",
        {
            url: "/units/",
            method: "GET",
        },
        {enabled: true},
        onSuccess,
    )
}

