import {CircularProgress} from "@material-ui/core"
import Avatar from "@material-ui/core/Avatar"
import Button from "@material-ui/core/Button"
import Container from "@material-ui/core/Container"
import CssBaseline from "@material-ui/core/CssBaseline"
import {makeStyles} from "@material-ui/core/styles"
import TextField from "@material-ui/core/TextField"
import Typography from "@material-ui/core/Typography"
import LockOutlinedIcon from "@material-ui/icons/LockOutlined"
import React, {useState} from "react"
import {useDispatch} from "react-redux"
import {login} from "../../context/auth"
import {useHistory} from "react-router-dom"
import {setAlert} from "../../context/alert"
import {useAxios} from "../../utils/request"

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}))

export default function Login() {
    const classes = useStyles()
    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")
    const history = useHistory()
    const dispatch = useDispatch()

    const {isLoading, refetch} = useAxios(
        "login",
        {
            url: `/api/token/`,
            method: "POST",
            data: {username, password},
        },
        {},
        (res) => {
            dispatch(
                login(
                    username,
                    res.data.access,
                    res.data.refresh,
                    res.data.is_superuser,
                    res.data.is_viewer,
                    res.data.id
                )
            )
            history.push("/")
        },
        () => {
            dispatch(
                setAlert({
                    severity: "error",
                    message: "You have entered an invalid username or password",
                })
            )
        }
    )

    function handleSubmit(e) {
        e.preventDefault()
        refetch()
    }

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Login
                </Typography>
                <form
                    className={classes.form}
                    noValidate
                    onSubmit={handleSubmit}
                >
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Username"
                        name="email"
                        autoFocus
                        onChange={(e) => setUsername(e.target.value)}
                        value={username}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        onChange={(e) => setPassword(e.target.value)}
                        value={password}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                    >
                        Sign In
                    </Button>

                    {isLoading && (
                        <div className={classes.paper}>
                            <CircularProgress />
                        </div>
                    )}
                </form>
            </div>
        </Container>
    )
}
