export const sortByNameComparator = (a, b) => {
    let lowerA = a.name.toLowerCase()
    let lowerB = b.name.toLowerCase()
    if (lowerA < lowerB) {
        return -1
    }
    if (lowerA > lowerB) {
        return 1
    }
    return 0
}
