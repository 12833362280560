import {
    Box,
    FormControl,
    InputLabel,
    makeStyles,
    MenuItem,
    Select,
} from "@material-ui/core"
import MaterialTable from "material-table"
import React, {useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import {tableIcons} from "../../components/tables/tableIcons"
import TopNavSecondary from "../../components/TopNavSecondary"
import {setUnit as setUnitID} from "../../context/alert"
import {sortByNameComparator} from "../../utils/functions"
import {request} from "../../utils/request"
import MaterialStockModal from "./modals/MaterialStockModal"
import {useMaterialStockExpressions} from "../../utils/api/expressions";
import {UNIT_ALL_ID} from "../../utils/api/alarms";
import {useUnits} from "../../utils/api/units";
import DeleteModal from "../../components/modals/DeleteModal"

const useStyles = makeStyles(() => ({
    date: {
        width: "14rem",
    },
}))

const actionMapping = (key) =>
    ({
        MR: "Manual correction",
        AD: "Automatic deduct",
    }[key])

export default function MaterialStock() {
    const unitID = useSelector((state) => state.unit)
    const [units, setUnits] = useState([])
    const [unit, setUnit] = useState(unitID)
    const [data, setData] = useState([])
    const [expression, setExpression] = useState(null)
    const [modalExpression, setModalExpression] = useState("")
    const tableRef = React.useRef(null)
    const [deleteMaterialStock, setDeleteMaterialStock] = React.useState(false)

    const dispatch = useDispatch()
    const classes = useStyles()

    useEffect(() => {
        setUnit(unitID === UNIT_ALL_ID ? undefined : unitID)
    },[unitID])

    useUnits(
        res=>{
            setUnits([...res.data.results.sort(sortByNameComparator)])
            if(unitID !== UNIT_ALL_ID){
                setUnit(unitID)
            }
            else{
                dispatch(setUnitID(res.data.results[0]))
            }
        }
    )

    const getExpressionsRequest = useMaterialStockExpressions(unit)

    useEffect(()=>{
        if(getExpressionsRequest.isSuccess){
            setData(getExpressionsRequest.data.data.results)
        }
        // eslint-disable-next-line
    },[getExpressionsRequest.data])


    useEffect(() => {
        getExpressionsRequest.refetch()
        setExpression(undefined)
        // eslint-disable-next-line
    }, [unit, modalExpression])

    useEffect(() => {
        tableRef.current.onQueryChange()
    }, [expression])

    const handleChange = (event) => {
        setUnit(event.target.value)
        dispatch(setUnitID(event.target.value))
    }

    return (
        <>
            {modalExpression && (
                <MaterialStockModal
                    open={modalExpression}
                    expression={modalExpression}
                    handleClose={() => {
                        setModalExpression(null)
                    }}
                />
            )}
            {deleteMaterialStock && (
                <DeleteModal
                    open={deleteMaterialStock}
                    handleClose={() => {
                        setDeleteMaterialStock(false)
                    }}
                    redirect={() => {
                        tableRef.current.onQueryChange()
                    }}
                    url={`/material_stocks/${deleteMaterialStock.id}/`}
                    deleteText={`DELETE MATERIAL STOCK`}
                    header={`Delete material stock ${deleteMaterialStock.id}?`}
                    alertText={"Material stock was deleted"}
                />
            )}

            <TopNavSecondary
                heading={"Material stock"}
                buttons={[
                    <FormControl className={classes.date}>
                        <InputLabel id="FormControl demo-simple-select-label">
                            Unit
                        </InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={unit}
                            onChange={handleChange}
                        >
                            {units.map((item, index) => (
                                <MenuItem key={index} value={item.id}>
                                    {item.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>,
                ]}
            />

            <MaterialTable
                icons={tableIcons}
                columns={[
                    {title: "Name", field: "name"},
                    {title: "Actual material stock", field: "stock_value",
                        render: (rowData) => {
                            if(rowData.stock_value){
                                return `${rowData.stock_value} [ ${rowData?.physical_unit} ]`
                            }
                            else{
                                return ""
                            }}
                    },
                    {title: "Value change", field: "difference",
                        render: (rowData) => {
                        if(rowData.difference){
                            return `${rowData.difference} [ ${rowData?.physical_unit} ]`
                        }
                        else{
                            return ""
                        }}
                    },
                    {title: "Last update", field: "created_at"},
                ]}
                data={data}
                title="Pulse expressions"
                isLoading={getExpressionsRequest.isLoading}
                actions={[
                    {
                        icon: tableIcons.Add,
                        tooltip: "Add stock value",
                        onClick: (event, rowData) => {
                            setModalExpression(rowData)
                        },
                    },
                ]}
                options={{
                    pageSize: 5,
                    actionsColumnIndex: -1,
                    search: false,
                    rowStyle: (rowData) => ({
                        backgroundColor:
                            expression?.id === rowData?.id ? "#EEE" : "#FFF",
                    }),
                }}
                onRowClick={(evt, selectedRow) => {
                    setExpression(selectedRow)
                }}
            />

            <Box my={2}/>

            <MaterialTable
                tableRef={tableRef}
                icons={tableIcons}
                columns={[
                    {title: "SMS Date", field: "sms_date", defaultSort: "desc"},
                    {title: "Real Date", field: "created_at"},
                    {title: "Stock value", field: "stock_value",
                        render: (rowData) => {
                            if(rowData.stock_value){
                                return `${rowData.stock_value} [ ${expression?.physical_unit} ]`
                            }
                            else{
                                return ""
                            }}
                    },
                    {title: "Diff", field: "difference",
                        render: (rowData) => {
                            if(rowData.difference){
                                return `${rowData.difference} [ ${expression?.physical_unit} ]`
                            }
                            else{
                                return ""
                            }}
                    },
                    {
                        title: "Action",
                        field: "action",
                        render: (rowData) => actionMapping(rowData?.action),
                    },
                    {title: "Note", field: "note", sorting: false},
                ]}
                data={(query) => {
                    return new Promise((resolve, reject) => {
                        if (expression) {
                            const conf = {
                                url: `material_stocks/`,
                                method: "GET",
                                params: {
                                    ordering:
                                        query.orderBy &&
                                        (query.orderDirection === "desc"
                                            ? "-"
                                            : "") + query.orderBy?.field,
                                    expression: expression?.id,
                                    search: query.search || undefined,
                                    limit: query.pageSize,
                                    offset: query.pageSize * query.page,
                                },
                            }

                            request(conf)
                                .then((res) => {
                                    resolve({
                                        data: res.data.results,
                                        page: res.data.results.length === 0 ? 0 : query.page,
                                        totalCount: res.data.count,
                                    })
                                })
                                .catch(() => {
                                    reject()
                                })
                        } else {
                            reject()
                        }
                    })
                }}
                title={
                    expression
                        ? `Recent stock adjustments: ${expression?.name}`
                        : "Expression not selected!"
                }
                options={{
                    pageSize: 10,
                    actionsColumnIndex: -1,
                    search: true,
                }}
                actions={[
                    {
                        icon: tableIcons.RefreshIcon,
                        tooltip: 'Refresh',
                        isFreeAction: true,
                        onClick: () => tableRef.current && tableRef.current.onQueryChange()
                    },
                    {
                        icon: tableIcons.Delete,
                        tooltip: "Delete material stock",
                        onClick: (event, rowData) => {
                            setDeleteMaterialStock(rowData)
                        },
                    },
                  ]}
            />
        </>
    )
}
