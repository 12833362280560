export const alarmType = {
    AH: "High",
    AL: "Low",
}

export const alarmTriggerType = {
    PE: "Permanent",
    ON: "On change",
}

export const smsIntervalTime = {
    111: "     ",
    0: "Not send",
    1: "5 minutes",
    2: "15 minutes",
    3: "30 minutes",
    4: "60 minutes",
    5: "2 hours",
    6: "6 hours",
    7: "12 hours",
    8: "24 hours",
}

export const controllModeEnum = {
    MANUAL: "Manual",
    ALARM: "Alarm",
    DOSING_PUMP: "Dosing Pump",
}

export const renameType = (type) => {
    let dict = {
        ...alarmType,
        ...alarmTriggerType,
        ...smsIntervalTime,
        ...controllModeEnum,
    }

    dict = {
        ...dict,
        ...Object.entries(dict).reduce((acc, [key, value]) => {
            acc[value] = key
            return acc
        }, {}),
    }

    return dict[type]
}
