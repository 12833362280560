import MaterialTable from "material-table"
import React, {useEffect, useState} from "react"
import {MissingAlarmModal, secondsToString} from "../modals/MissingAlarmModal"
import DeleteModal from "../../../../components/modals/DeleteModal"
import {tableIcons} from "../../../../components/tables/tableIcons"
import {useAxios} from "../../../../utils/request"
import {renameType} from "../../../../utils/enumMapping"


export default function MissingAlarmTable({id, actionType}) {
    const [data, setData] = useState([])
    const [modalAdd, setModalAdd] = useState(false)
    const [modalDelete, setModalDelete] = useState(false)
    const [editModal, setEditModal] = useState(false)

    const {refetch, isLoading} = useAxios(
        ["missingAlarmsTable", id],
        {
            url: `/alarms_missing/`,
            method: "GET",
            params:{
                unit: id,
                action_type: actionType
            },
        },
        {},
        (res) => {
            setData(
                res.data.results.map((item) => ({
                    ...item,
                    type: item.type === "AH" ? "High" : "Low",
                }))
            )
        }
    )

    useEffect(
        (_) => {
            refetch()
        },
        // eslint-disable-next-line
        [modalAdd, modalDelete]
    )

    const deleteRow = (event, rowData) => {
        setModalDelete(rowData)
    }

    return (
        <>
            {modalAdd && (
                <MissingAlarmModal
                    open={modalAdd}
                    handleClose={() => {
                        setModalAdd(false)
                        setEditModal(false)
                    }}
                    unit={id}
                    edit={editModal}
                />
            )}
            <DeleteModal
                open={!!modalDelete}
                handleClose={() => {
                    setModalDelete(false)
                }}
                url={`/alarms_missing/${modalDelete.id}/`}
                deleteText="DELETE MISSING ALARM"
                header={`Delete missing alarm ${modalDelete.name}?`}
                alertText={"Missing alarm was deleted"}
            />

            <MaterialTable
                icons={tableIcons}
                columns={[
                    {title: "Name", field: "name"},
                    {
                        title: "Trigger type",
                        field: "trigger_type",
                        render: (rowData) => renameType(rowData.trigger_type),
                    },
                    {
                        title: "Silenced",
                        field: "enabled",
                        render: (rowData) => rowData.enabled ? 'no' : 'yes'
                    },
                    {
                        title: "E-mails",
                        render: (rowData) =>
                            rowData.email_action_detail?.emails
                                .reduce((acc, item) => acc + ", " + item, "")
                                .substring(1),
                    },
                    {
                        title: "Interval",
                        field: "interval",
                        render: (rowData) => secondsToString(rowData.interval),
                    },
                ]}
                data={data}
                title="Missing alarm"
                isLoading={isLoading}
                actions={[
                    {
                        icon: tableIcons.Add,
                        tooltip: "Add missing alarm",
                        isFreeAction: true,
                        onClick: () => setModalAdd(true),
                    },
                    {
                        icon: tableIcons.Delete,
                        tooltip: "Delete alarm",
                        onClick: deleteRow,
                    },
                    {
                        icon: tableIcons.Edit,
                        tooltip: "Edit alarm",
                        onClick: (event, rowData) => {
                            setEditModal(rowData)
                            setModalAdd(true)
                        },
                    },
                ]}
                options={{
                    pageSize: 10,
                    actionsColumnIndex: -1,
                    search: false,
                }}
            />
        </>
    )
}
