import {
    Box,
    FormControl,
    InputLabel,
    makeStyles,
    MenuItem,
    Select,
    Typography,
    FormControlLabel,
    Checkbox
} from "@material-ui/core"
import MaterialTable from "material-table"
import React, { useEffect, useState } from "react"
import {useDispatch, useSelector} from "react-redux"
import { tableIcons } from "../../components/tables/tableIcons"
import TopNavSecondary from "../../components/TopNavSecondary"
import {setAlert, setUnit as setUnitID, setTriggered} from "../../context/alert"
import { sortByNameComparator } from "../../utils/functions"
import WarningIcon from "@material-ui/icons/Warning"
import {
    getAlarmHistory, UNIT_ALL_ID, useAcknowledgeAlarm, useAcknowledgeAll, useAlarmLatestHistory, useAcknowledgeAllTypes,
} from "../../utils/api/alarms";
import {useUnits} from "../../utils/api/units";
import {WarningModal} from "../../components/modals/WarningModal";


const useStyles = makeStyles(() => ({
    date: {
        width: "14rem",
    },
}))

const customCellState = (rowData) => {

    const states = {
        ack_active: {
            color: "#ffc400",
            name: 'Acknowledged active'
        },
        unack_active: {
            color: "#ff1744",
            name: 'Unacknowledged active'
        },
        unack_inactive: {
            color: "#757575",
            name: 'Unacknowledged inactive'
        },
        ack_inactive: {
            color: "#bdbdbd",
            name: 'Acknowledged inactive'
        }
    }

    let state = {}

    if (rowData.triggered) {
        if (rowData.acknowledged && rowData.is_actual){
            state = states.ack_active
        }
        else if (!rowData.acknowledged && rowData.is_actual){
            state = states.unack_active
        }
        else if (!rowData.acknowledged && !rowData.is_actual){
            state = states.unack_inactive
        }
        else if (rowData.acknowledged && !rowData.is_actual){
            state = states.ack_inactive
        }
    } else {
        if (!rowData.acknowledged){
            state = states.unack_inactive
        }
        else if (rowData.acknowledged){
            state = states.ack_inactive
        }
    }


    return (
        <Box display="flex" flexDirection="row">
            <Typography>{state.name}</Typography>
            <Box mx={2}>
                <WarningIcon style={{fill: state.color}}/>
            </Box>
        </Box>
    )
}

const renderContext = (data) => {    
    return (JSON.stringify(data.context))
}

export default function AlarmStatus() {
    const [units, setUnits] = useState([])
    const [unit, setUnit] = useState("")
    const [data, setData] = useState([])
    const [alarm, setAlarm] = useState(null)
    const tableRef = React.useRef(null)
    const [warningModal, setWarningModal] = useState({open: false})

    const dispatch = useDispatch()
    const classes = useStyles()

    const unitId = useSelector((state) => state.unit)

    useEffect(()=>{
        setUnit(unitId)
    },[unitId])

    useEffect(()=>{
        dispatch(setUnitID(UNIT_ALL_ID))
        // eslint-disable-next-line
    },[])

    useUnits(
        res=>{
            setUnits([{name:'ALL', id: UNIT_ALL_ID }, ...res.data.results.sort(sortByNameComparator)])
        }
    )
    
    const triggered = useSelector((state) => state.triggered)
     useEffect(()=>{
        setTriggered(triggered)
    },[triggered])

    const alarmsLatestStatus = useAlarmLatestHistory(unitId, triggered)
    const ackAlarm = useAcknowledgeAlarm()
    const ackAll = useAcknowledgeAll()
    const ackAllTypes = useAcknowledgeAllTypes()


    useEffect(()=>{
        if(alarmsLatestStatus.isSuccess){
            setData(alarmsLatestStatus.data)
        }
        // eslint-disable-next-line
    },[alarmsLatestStatus.data])


    const handleChange = (event) => {
        setUnit(event.target.value)
        dispatch(setUnitID(event.target.value))
    }

    const setIsTriggered = (event) => {
        setTriggered(event.target.checked)
        dispatch(setTriggered(event.target.checked))
    }

    const handleRowClick = (evt, selectedRow) => {
        setAlarm(selectedRow)
    }

    const handleAck = (event, rowData) => {
        setWarningModal({
            title: "Ackowledge alarm?",
            open: true,
            handleSubmit: () => {
                ackAlarm.mutateAsync(rowData)
                    .then(()=>{
                        dispatch(setAlert({severity: "success", message: "Alarm ackknowledged"}))
                    })
                    .catch(()=>{
                        dispatch(setAlert({severity: "error", message: "Acknowledgement unsuccessful"}))
                    })
                    .finally(()=>{
                        setWarningModal({open: false})
                        alarmsLatestStatus.refetch()
                        tableRef.current.onQueryChange()
                    })
            },
            handleClose: () => {setWarningModal({open: false})},
            submitButtonLabel: "Acknowledge",
            cancelButtonLabel: "Cancel"
        })
    }

    const handleAckAll = () => {
        setWarningModal({
            title: "Acknowledge all?",
            open: true,
            handleSubmit: () => {
                ackAll.mutateAsync({
                    alarmContentType: alarm.alarm_content_type,
                    alarmId: alarm.alarm_id
                })
                    .then(()=>{
                        dispatch(setAlert({severity: "success", message: "Alarm ackknowledged"}))
                    })
                    .catch(()=>{
                        dispatch(setAlert({severity: "error", message: "Acknowledgement unsuccessful"}))
                    })
                    .finally(()=>{
                        setWarningModal({open: false})
                        alarmsLatestStatus.refetch()
                        tableRef.current.onQueryChange()
                    })
            },
            handleClose: () => {setWarningModal({open: false})},
            submitButtonLabel: "Ackowledge all",
            cancelButtonLabel: "Cancel"
        })
    }

    const handleAckAllTypes = () => {
        setWarningModal({
            title: "Ackowledge all types?",
            open: true,
            handleSubmit: () => {
                ackAllTypes.mutateAsync({
                    alarms: data.map(item => ({ alarm_id: item.alarm_id, alarm_content_type: item.alarm_content_type }))
                })
                    .then(()=>{
                        dispatch(setAlert({severity: "success", message: "Alarm ackknowledged"}))
                    })
                    .catch(()=>{
                        dispatch(setAlert({severity: "error", message: "Acknowledgement unsuccessful"}))
                    })
                    .finally(()=>{
                        setWarningModal({open: false})
                        alarmsLatestStatus.refetch()
                        tableRef.current.onQueryChange()
                    })
            },
            handleClose: () => {setWarningModal({open: false})},
            submitButtonLabel: "Ackowledge all types",
            cancelButtonLabel: "Cancel"
        })
    }

    useEffect(() => {
        tableRef.current.onQueryChange()
    }, [alarm, unitId])

    useEffect(() => {
        setAlarm(null)
    }, [unitId])

    return (
        <>  
            {warningModal.open &&
                <WarningModal {...warningModal}
                />
            }
            <TopNavSecondary
                heading={"Alarm status"}
                buttons={[
                    <FormControlLabel
                        className={classes.date}
                        label={triggered ? "Triggered" : "Untriggered"}
                        control={
                            <Checkbox
                                checked={triggered}
                                onChange={e => setIsTriggered(e)}
                            />
                        }
                    />,
                    <FormControl className={classes.date}>
                        <InputLabel>
                            Unit
                        </InputLabel>
                        <Select
                            value={unit}
                            onChange={handleChange}
                        >
                            {units.map((item, index) => (
                                <MenuItem key={index} value={item.id}>
                                    {item.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>,
                ]}
            />

            <MaterialTable
                tableRef={tableRef}
                icons={tableIcons}
                columns={[
                    { title: "Unit name", field: "unit_name" },
                    {title: "Alarm type", field: "type"},
                    { title: "Alarm name", field: "alarm_name" },
                    {
                        title: "State",
                        render: customCellState
                    },
                    { title: "SMS Time & Date", field: "sms_date" },
                ]}
                data={data}
                title="Alarms status"
                actions={[
                    {
                        icon: tableIcons.Check,
                        tooltip: "Acknowledge",
                        onClick: handleAck,
                    },
                    {
                        icon: tableIcons.Check,
                        tooltip: "Acknowledge all types",
                        isFreeAction: true,
                        onClick: handleAckAllTypes,
                    },
                    {
                        icon: tableIcons.RefreshIcon,
                        tooltip: 'Refresh',
                        isFreeAction: true,
                        onClick: () => tableRef.current && tableRef.current.onQueryChange()
                    },
                ]}
                options={{
                    pageSize: 5,
                    actionsColumnIndex: -1,
                    search: false,
                    rowStyle: (rowData) => ({
                        backgroundColor:
                            alarm?.id === rowData?.id ? "#EEE" : "#FFF",
                    }),
                }}
                onRowClick={handleRowClick}
            />

            <Box my={2}/>

            <MaterialTable
                tableRef={tableRef}
                icons={tableIcons}
                columns={[
                    { title: "Unit name", field: "unit_name", sorting: false },
                    { title: "Alarm type", field: "type", sorting: false},
                    { title: "Alarm name", field: "alarm_name", sorting: false},
                    {
                        title: "State",
                        render: customCellState,
                        sorting: false
                    },
                    {
                        title: "Context",
                        render: renderContext,
                        sorting: false
                    },
                    { title: "SMS Time & Date", field: "sms_date", defaultSort: "desc" },
                    { title: "Real Time & Date", field: "created_at"},
                ]}
                data={(query) => {
                    return new Promise((resolve, reject) => {
                        if(alarm?.alarm_content_type){
                            getAlarmHistory(alarm.alarm_content_type, alarm.alarm_id, query)
                                .then((res) => {
                                    resolve({
                                        data: res.data.results,
                                        page: res.data.results.length === 0 ? 0 : query.page,
                                        totalCount: res.data.count,
                                    })
                                })
                                .catch(() => {
                                    reject()
                                })
                        }
                        else{
                            reject("Alarm not selected")
                        }

                    })
                }}
                actions={[
                    {
                        icon: tableIcons.Check,
                        tooltip: "Acknowledge",
                        onClick: handleAck,
                    },
                    {
                        icon: tableIcons.Check,
                        tooltip: "Acknowledge all",
                        isFreeAction: true,
                        onClick: handleAckAll,
                    },
                    {
                        icon: tableIcons.RefreshIcon,
                        tooltip: 'Refresh',
                        isFreeAction: true,
                        onClick: () => tableRef.current && tableRef.current.onQueryChange()
                    },
                ]}
                title={
                    alarm
                        ? `Alarm history: ${alarm?.alarm_name}`
                        : "Alarm not selected"
                }
                options={{
                    pageSize: 10,
                    actionsColumnIndex: -1,
                    search: false,
                }}
            />
        </>
    )
}
