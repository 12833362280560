import {
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from "@material-ui/core"
import Button from "@material-ui/core/Button"
import Grid from "@material-ui/core/Grid"
import {makeStyles} from "@material-ui/core/styles"
import React from "react"

const useStyles = makeStyles((theme) => ({
    form: {
        "& > *": {
            margin: theme.spacing(1),
        },
    },
}))

export default function CommonModal({
    title = "Untitled",
    open = true,
    dialogProps = {},
    cancelButtonLabel = "Cancel",
    submitButtonLabel = "Submit",
    handleClose = () => {
        console.log("Closing modal")
    },
    handleSubmit = () => {
        console.log("Submitting modal")
    },
    loading = false,
    children = false,
}) {
    const defaultDialogProps = {
        fullWidth: true,
        maxWidth: "sm",
        open: open,
        onClose: handleClose,
        "aria-labelledby": "scroll-dialog-title",
        "aria-describedby": "scroll-dialog-description",
        ...dialogProps,
    }

    const formId = `form-${title}`
    const classes = useStyles()

    return (
        <Dialog {...defaultDialogProps}>
            <DialogTitle id={`scroll-dialog-${title}`}>{title}</DialogTitle>
            {
                children
                &&
                <DialogContent dividers={true}>

                    <Grid
                        container
                        direction="row"
                        justify="flex-start"
                        alignItems="center"
                    >
                        <Grid item>
                            <form
                                className={classes.form}
                                id={formId}
                                onSubmit={handleSubmit}
                            >
                                <>{children}</>
                            </form>
                        </Grid>
                    </Grid>


                </DialogContent>
            }

            <DialogActions>
                <Grid
                    container
                    justify="flex-end"
                    alignItems="center"
                    spacing={2}
                >
                    <Grid item>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={handleClose}
                        >
                            {cancelButtonLabel}
                        </Button>
                    </Grid>
                    <Grid item>
                        {loading ? (
                            <CircularProgress />
                        ) : (
                            <Button
                                onClick={handleSubmit}
                                form={formId}
                                variant="contained"
                                color="primary"
                            >
                                {submitButtonLabel}
                            </Button>
                        )}
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    )
}
