import {
    Box,
    FormControl,
    InputLabel,
    makeStyles,
    MenuItem, Paper,
    Select, Typography,
} from "@material-ui/core"
import MaterialTable from "material-table"
import React, {useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import {tableIcons} from "../../components/tables/tableIcons"
import TopNavSecondary from "../../components/TopNavSecondary"
import {setAlert, setUnit as setUnitID} from "../../context/alert"
import {sortByNameComparator} from "../../utils/functions"
import TabsWrapper from "../../components/tabs/TabsWrapper";
import {
    ACTION_TYPE, UNIT_ALL_ID,
    useAllAlarms,
    usePatchAlarm
} from "../../utils/api/alarms";
import { useUnits} from "../../utils/api/units";
import {AddAlarmSilenceModal} from "./modals/AddAlarmSilenceModal";
import {WarningModal} from "../../components/modals/WarningModal";
import BasicAlarmTable from "../Units/Unit/tables/BasicAlarmTable";
import PulseCombinedAlarmTable from "../Units/Unit/tables/PulseCombinedAlarmTable";
import PulseAlarmTable from "../Units/Unit/tables/PulseAlarmTable";
import AnalogIntervalAlarmTable from "../Units/Unit/tables/AnalogIntervalAlarmTable";


const useStyles = makeStyles(() => ({
    date: {
        width: "14rem",
    },
    alarmsGroups:{
        width: 200
    }
}))




const alarmsTabs = (unitId, actionType=ACTION_TYPE.ENA) => [
    {
        label: "Analog Alarms",
        module: <BasicAlarmTable id={unitId} actionType={actionType} />,
    },
    {
        label: "Analog Interval Alarms",
        module: <AnalogIntervalAlarmTable id={unitId} actionType={actionType} />,
    },
    {
        label: "Digital alarms",
        module: <BasicAlarmTable id={unitId} digital={true} actionType={actionType}/>,
    },
    {
        label: "Combined pulse alarms",
        module: <PulseCombinedAlarmTable id={unitId} actionType={actionType}/>,
    },
    {
        label: "Pulse alarms",
        module: <PulseAlarmTable id={unitId} actionType={actionType}/>,
    },
]

export default function AlarmSilence() {
    const [units, setUnits] = useState([])
    const [modalWarning, setModalWarning] = useState(false)
    const [data, setData] = useState([])
    const [addSilenceAlarmModal, setAddSilenceAlarmModal] = useState(false)
    const unitId = useSelector((state) => state.unit)


    const dispatch = useDispatch()
    const classes = useStyles()

    const patchAlarm = usePatchAlarm()

    useUnits(
        res=>{
            setUnits([{name:'ALL', id: -1 }, ...res.data.results.sort(sortByNameComparator)])
        }
    )

    const [digiAlarms, analogAlarms, analogIntervalAlarms, pulseCombinedAlarms, pulseAlarms, materialStockAlarms] = useAllAlarms(unitId, false)

    useEffect(()=>{
        dispatch(setUnitID(UNIT_ALL_ID))
        // eslint-disable-next-line
    },[])


    useEffect(()=>{
        if(
            digiAlarms.isSuccess && analogAlarms.isSuccess && analogIntervalAlarms.isSuccess && pulseCombinedAlarms.isSuccess && pulseAlarms.isSuccess && materialStockAlarms.isSuccess
        )
        {
            setData(
                [
                    ...digiAlarms.data.results,
                    ...analogAlarms.data.results,
                    ...analogIntervalAlarms.data.results,
                    ...pulseCombinedAlarms.data.results,
                    ...pulseAlarms.data.results,
                    ...materialStockAlarms.data.results
                ]
            )
        }
        // eslint-disable-next-line
    },[
        digiAlarms.data,
        analogAlarms.data,
        analogIntervalAlarms.data,
        pulseCombinedAlarms.data,
        pulseAlarms.data,
        materialStockAlarms.data
    ])

    useEffect(()=>{
        digiAlarms.refetch()
        analogAlarms.refetch()
        analogIntervalAlarms.refetch()
        pulseCombinedAlarms.refetch()
        pulseAlarms.refetch()
        materialStockAlarms.refetch()
        // eslint-disable-next-line
    },[addSilenceAlarmModal,modalWarning])

    const handleChange = (event) => {
        dispatch(setUnitID(event.target.value))
    }

    return (
        <>
            {
                addSilenceAlarmModal &&
                <AddAlarmSilenceModal
                    unitId={unitId}
                    open={Boolean(addSilenceAlarmModal)}
                    handleClose={() => { setAddSilenceAlarmModal(false)}}
                />
            }

            {
                modalWarning &&
                    <WarningModal
                        title={`Unsilence alarm ${modalWarning.name}?`}
                        submitButtonLabel="Unsilence alarm"
                        open={Boolean(modalWarning)}
                        handleClose={() => { setModalWarning(false)}}
                        handleSubmit={()=>{
                            patchAlarm.mutateAsync({
                                id: modalWarning.id,
                                alarmType: modalWarning.alarmType,
                                data: {
                                    enabled: true
                                }
                            })
                                .then(()=>{
                                dispatch(
                                    setAlert({
                                        severity: "success",
                                        message:
                                            "Alarm unsilenced",
                                    })
                                )

                            })
                                .catch(()=>{
                                dispatch(
                                    setAlert({
                                        severity: "error",
                                        message:
                                            "Alarm unsilencing failed",
                                    })
                                )
                            })
                                .finally(()=>{
                                    setModalWarning(false)
                                })

                        }}
                        loading={patchAlarm.isLoading}
                    />
            }




            <TopNavSecondary
                heading={"Alarm silencing"}
                buttons={[
                    <FormControl className={classes.date}>
                        <InputLabel id="FormControl demo-simple-select-label">
                            Unit
                        </InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={unitId}
                            onChange={handleChange}
                        >
                            {units.map((item, index) => (
                                <MenuItem key={index} value={item.id}>
                                    {item.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>,
                ]}
            />

            <MaterialTable
                icons={tableIcons}
                columns={[
                    {title: "Unit", field: "unit_name"},
                    {title: "Type", field: "alarmType", render: rowData => rowData.alarmType.name},
                    {title: "Silenced", field: "enabled", render: (rowData) => rowData.enabled ? 'no' : 'yes'},
                    {title: "Name", field: "name"},
                    {title: "Reactivate conditions", field: "created_at"},
                ]}
                data={data}
                title="Silence alarms"
                isLoading={analogAlarms.isLoading && digiAlarms.isLoading && pulseCombinedAlarms.isLoading && pulseAlarms.isLoading}
                actions={[
                    {
                        icon: tableIcons.Add,
                        tooltip: "Add silence alarm",
                        isFreeAction: true,
                        onClick: () => setAddSilenceAlarmModal(true),
                    },
                    {
                        icon: tableIcons.Delete,
                        tooltip: "Remove silence alarm",
                        onClick: (event, rowData) => {
                            setModalWarning(rowData)
                        },
                    },
                ]}
                options={{
                    pageSize: 5,
                    actionsColumnIndex: -1,
                    search: false,
                }}
            />
            <Box my={2} />
            <Paper>
                <Box paddingLeft={3} paddingY={2}>
                    <Typography  variant={"h6"}>Reactivate alarms</Typography>
                </Box>


                <TabsWrapper tabs={alarmsTabs(unitId, ACTION_TYPE.ENA)} />
            </Paper>



        </>
    )
}
