import React from "react"
import CommonModal from "./CommonModal";


export function WarningModal({
     title = "Warning modal",
     open = false,
     cancelButtonLabel = "No",
     submitButtonLabel = "Remove from silenced",
     handleClose = () => {
         console.log("Closing modal")
     },
     handleSubmit = () => {
         console.log("Submitting modal")
     }, loading = false,
}){    return (
        <CommonModal
            title={title}
            open={open}
            handleClose= {handleClose}
            handleSubmit= {handleSubmit}
            cancelButtonLabel = {cancelButtonLabel}
            submitButtonLabel = {submitButtonLabel}
            loading= {loading}
        />

    )
}