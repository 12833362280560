import MaterialTable from "material-table"
import React from "react"
import {tableIcons} from "../../../components/tables/tableIcons"

export default function CustomTable(props) {
    return (
        <MaterialTable
            icons={tableIcons}
            columns={[
                {title: "Date", field: "date"},
                ...props.expressions.map((exp) => ({
                    title: exp.name,
                    field: `${exp.value}`,
                })),
            ]}
            data={props.data}
            title={props.name}
            options={{
                pageSize: 10,
            }}
        />
    )
}
