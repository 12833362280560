import {Box, Typography} from "@material-ui/core"
import WarningIcon from "@material-ui/icons/Warning"
import MaterialTable from "material-table"
import moment from "moment"
import React from "react"
import {useSelector} from "react-redux"
import {tableIcons} from "../../../components/tables/tableIcons"
import {HtmlTooltip} from "../../../components/tooltips/WhiteToolTip"

const customRow = (rowData, key, dateKey, selectedDate) => {
    return (
        <Box display="flex" flexDirection="row">
            <Typography>{rowData[key]}</Typography>
            {rowData[dateKey] !== moment(selectedDate).format("YYYY-MM-DD") && (
                <HtmlTooltip title={`Date mismatch: ${rowData[dateKey]}`}>
                    <Box marginLeft={2}>
                        <WarningIcon color="secondary" />
                    </Box>
                </HtmlTooltip>
            )}
        </Box>
    )
}

export default function CustomTable(props) {
    const dateFrom = useSelector((state) => state.dateFrom)
    const dateTo = useSelector((state) => state.dateTo)

    const parseTable = (data, expressions) => {
        if (!data.length) {
            return []
        }
        let test = expressions.map((expr) => {
            let val1 = data[0]
            let date1 = val1.date.substring(0, 10)

            if (val1 && expr.value in val1) {
                val1 = val1[expr.value]
            } else {
                val1 = null
            }

            let val2 = data.slice(-1).pop()
            let date2 = val2.date.substring(0, 10)
            if (val2 && expr.value in val2) {
                val2 = val2[expr.value]
            } else {
                val2 = null
            }

            return {
                expression: expr.name,
                val1,
                val2,
                diff: val1 - val2,
                date1,
                date2,
            }
        })
        return test
    }

    return (
        <MaterialTable
            icons={tableIcons}
            columns={[
                {title: "Expression", field: "expression"},
                {
                    title: "Latest value",
                    field: "val1",
                    render: (rowData) =>
                        customRow(rowData, "val1", "date1", dateTo),
                },
                {
                    title: "Oldest value",
                    field: "val2",
                    render: (rowData) =>
                        customRow(rowData, "val2", "date2", dateFrom),
                },
                {title: "Difference", field: "diff"},
            ]}
            data={parseTable(props.pulseData, props.pulseExpressions)}
            title={props.name}
            options={{
                pageSize: 10,
            }}
        />
    )
}
