import DateFnsUtils from "@date-io/date-fns"
import {
    Box,
    Button,
    CircularProgress,
    FormControl,
    InputLabel,
    makeStyles,
    MenuItem,
    Select,
} from "@material-ui/core"
import Typography from "@material-ui/core/Typography"
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers"
import React, {useEffect, useState} from "react"
import {useSelector} from "react-redux"
import {request} from "../../utils/request"
import TopNavSecondary from "../../components/TopNavSecondary"

const useStyles = makeStyles((theme) => ({
    form: {
        width: 400,
        marginTop: 20,
        marginBottom: 20,
    },
    formControl: {
        marginTop: 20,
        minWidth: 200,
    },
}))

export default function Export() {
    const url = useSelector((state) => state.baseUrl)
    const classes = useStyles()
    const [loading, setLoading] = useState(false)
    const [unitLoading, setUnitLoading] = useState(true)
    const [selectedDateTo, setSelectedDateTo] = useState(new Date())
    const [selectedDateFrom, setSelectedDateFrom] = useState(
        new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 7)
    )
    const [unit, setUnit] = useState("")
    const [units, setUnits] = useState([])

    const downloadCsv = () => {
        let dateTo = new Date(
            selectedDateTo.setHours(23, 59, 59, 999) -
                selectedDateTo.getTimezoneOffset() * 60000
        )
            .toISOString()
            .slice(0, -1)
        let dateFrom = new Date(
            selectedDateFrom.setHours(0, 0, 0, 0) -
                selectedDateFrom.getTimezoneOffset() * 60000
        )
            .toISOString()
            .slice(0, -1)

        const conf = {
            url: `/export/?unit=${unit}&date_from=${dateFrom}&date_to=${dateTo}`,
            method: "GET",
            data: null,
        }
        setLoading(true)
        request(conf)
            .then((response) => {
                let blob = new Blob([response.data])
                let url = window.URL.createObjectURL(blob)
                let a = document.createElement("a")
                a.href = url
                a.download = "export.csv"
                a.click()
                setLoading(false)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const handleDateToChange = (date) => {
        setSelectedDateTo(date)
    }
    const handleDateFromChange = (date) => {
        setSelectedDateFrom(date)
    }

    useEffect(() => {
        const conf = {
            url: url + "/units/",
            method: "GET",
        }
        request(conf)
            .then((res) => {
                setUnits(res.data.results)
                if (res.data.results.length > 0) setUnit(res.data.results[0].id)
            })
            .catch((err) => {})
            .finally(() => {
                setUnitLoading(false)
            })
        // eslint-disable-next-line
    }, [])

    if (unitLoading) {
        return (
            <>
                <CircularProgress />
            </>
        )
    }

    if (!unitLoading && units.length === 0) {
        return <Typography variant="h2">No units</Typography>
    }

    return (
        <>
            <TopNavSecondary heading={"Export"} />
            <Box>
                <form className={classes.form}>
                    <FormControl
                        variant="outlined"
                        className={classes.formControl}
                    >
                        <InputLabel>Unit</InputLabel>
                        <Select
                            id="demo-simple-select-outlined"
                            value={unit}
                            onChange={(e) => setUnit(e.target.value)}
                            label="Unit"
                        >
                            {units.map((unit) => (
                                <MenuItem key={unit.id} value={unit.id}>
                                    {unit.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            disableToolbar
                            variant="inline"
                            format="dd/MM/yyyy"
                            margin="normal"
                            label="From"
                            value={selectedDateFrom}
                            onChange={handleDateFromChange}
                            KeyboardButtonProps={{
                                "aria-label": "change date",
                            }}
                        />
                    </MuiPickersUtilsProvider>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            disableToolbar
                            variant="inline"
                            format="dd/MM/yyyy"
                            margin="normal"
                            label="To"
                            value={selectedDateTo}
                            onChange={handleDateToChange}
                            KeyboardButtonProps={{
                                "aria-label": "change date",
                            }}
                        />
                    </MuiPickersUtilsProvider>
                    <Box>
                        {loading ? (
                            <CircularProgress />
                        ) : (
                            <Button
                                onClick={downloadCsv}
                                className={classes.formControl}
                                variant="outlined"
                            >
                                Export
                            </Button>
                        )}
                    </Box>
                </form>
            </Box>
        </>
    )
}
