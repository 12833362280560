import {Button} from "@material-ui/core"
import React, {useEffect} from "react"
import AddUnitModal from "./modals/UnitModal"
import TopNavSecondary from "../../components/TopNavSecondary"
import MaterialTable from "material-table";
import {tableIcons} from "../../components/tables/tableIcons";
import {useUnits} from "../../utils/api/units";
import {Redirect} from "react-router-dom";
import {sortByNameComparator} from "../../utils/functions";


export default function Units() {
    const [open, setOpen] = React.useState(false)
    const [units, setUnits] = React.useState([])
    const [redirect, setRedirect] = React.useState("")

    const unitRequest = useUnits()

    const handleRowClick = (evt, selectedRow) => {
        setRedirect(selectedRow.id)
    }

    const handleOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
        unitRequest.refetch()
    }

    useEffect(() => {
        if (unitRequest.isSuccess) {
            console.log(unitRequest.data.data.results)
            setUnits(unitRequest.data.data.results.sort(sortByNameComparator))
        }
        // eslint-disable-next-line
    }, [unitRequest.data])

    if (redirect) {
        return <Redirect to={"/" + redirect} />
    }

    return (
        <>
            <TopNavSecondary
                heading={"Units"}
                buttons={[
                    <Button
                        className="btn primary"
                        variant="contained"
                        color="primary"
                        onClick={handleOpen}
                    >
                        ADD NEW UNIT
                    </Button>,
                ]}
            />
            {open && <AddUnitModal open={open} handleClose={handleClose} />}
            <MaterialTable
                icons={tableIcons}
                columns={[
                    {title: "Name", field: "name"},
                    {title: "Phone number", field: "phone_number"},
                ]}
                data={units}
                isLoading={unitRequest.isLoading}
                onRowClick={handleRowClick}
                options={{
                    pageSize: 10,
                    actionsColumnIndex: -1,
                    search: false,
                    showTitle: false
                }}
            />
        </>
    )
}
