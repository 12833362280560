import MaterialTable from "material-table"
import DeleteModal from "../modals/DeleteModal"
import React from "react"
import EditEntryModal from "../modals/EditEntryModal"
import {tableIcons} from "./tableIcons"
import {getEntries} from "../../utils/api/expressions";

export default function CustomTable(props) {
    const [deleteEntry, setDeleteEntry] = React.useState(false)
    const [editEntry, setEditEntry] = React.useState(false)
    const tableRef = React.useRef(null)

    return (
        <>
            {deleteEntry && (
                <DeleteModal
                    open={deleteEntry}
                    handleClose={() => {
                        setDeleteEntry(false)
                    }}
                    redirect={() => {
                        tableRef.current.onQueryChange()
                    }}
                    url={`/entries/${deleteEntry.id}/`}
                    deleteText={`DELETE ENTRY`}
                    header={`Delete entry ${deleteEntry.id}?`}
                    alertText={"Entry was deleted"}
                />
            )}
            {editEntry && (
                <EditEntryModal
                    open={Boolean(editEntry)}
                    handleClose={() => {
                        setEditEntry(false)
                        tableRef.current.onQueryChange()
                    }}
                    entry={editEntry}
                />
            )}

            <MaterialTable
                tableRef={tableRef}
                icons={tableIcons}
                columns={[
                    {
                        title: "Date",
                        field: "date",
                        cellStyle: {
                            minWidth: "10rem",
                        },
                    },
                    {
                        title: "Entry",
                        field: "content",
                        sorting: false,
                        cellStyle: {
                            wordWrap: "break-word",
                            maxWidth: "60rem",
                        },
                    },
                ]}
                data={(query) => {
                    return new Promise((resolve, reject) => {
                        getEntries(props.id, query)
                            .then((res) => {
                                resolve({
                                    data: res.data.results.map((item) => ({
                                        ...item,
                                        content: JSON.stringify(
                                            item.content
                                        ).slice(1, -1),
                                    })),
                                    page: query.page,
                                    totalCount: res.data.count,
                                })
                            })
                            .catch((err) => {
                                reject(err)
                            })
                    })
                }}
                title="Entries"
                actions={[
                    {
                        icon: tableIcons.Delete,
                        tooltip: "Delete entry",
                        onClick: (event, rowData) => {
                            setDeleteEntry(rowData)
                        },
                    },
                    {
                        icon: tableIcons.Edit,
                        tooltip: "Edit entry",
                        onClick: (event, rowData) => {
                            setEditEntry(rowData)
                        },
                    },
                ]}
                options={{
                    pageSize: 10,
                    search: false
                }}
            />
        </>
    )
}
