export const SET_ALERT = "SET_ALERT"
export const CLEAR_ALERT = "CLEAR_ALERT"
export const SET_DIALOG = "SET_DIALOG"
export const CLEAR_DIALOG = "CLEAR_DIALOG"
export const SET_DATE = "SET_DATE"
export const SET_UNIT = "SET_UNIT"
export const SET_TRIGGERED = "SET_TRIGGERED"

export const setUnit = (unit) => ({
    type: SET_UNIT,
    payload: {unit},
})

export const setTriggered = (triggered) => ({
    type: SET_TRIGGERED,
    payload: {triggered},
})

export const setDate = (dateFrom, dateTo) => ({
    type: SET_DATE,
    payload: {dateFrom, dateTo},
})

export const setAlert = (state) => ({
    type: SET_ALERT,
    payload: state,
})

export const clearAlert = () => ({
    type: CLEAR_ALERT,
})

export const setDialog = (state) => ({
    type: SET_DIALOG,
    payload: state,
})

export const clearDialog = () => ({
    type: CLEAR_DIALOG,
})
