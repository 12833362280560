import AppBar from "@material-ui/core/AppBar"
import Box from "@material-ui/core/Box"
import Paper from "@material-ui/core/Paper"
import {makeStyles} from "@material-ui/core/styles"
import Tab from "@material-ui/core/Tab"
import Tabs from "@material-ui/core/Tabs"
import Typography from "@material-ui/core/Typography"
import {IndeterminateCheckBoxOutlined} from "@material-ui/icons"
import PropTypes from "prop-types"
import React from "react"

function TabPanel(props) {
    const {children, value, index, ...other} = props

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={0}>
                    <Typography component={"span"}>{children}</Typography>
                </Box>
            )}
        </div>
    )
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
}

function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        "aria-controls": `scrollable-auto-tabpanel-${index}`,
    }
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        width: "100%",
        backgroundColor: theme.palette.background.paper,
    },
}))

export default function TabsWrapper(props) {
    const classes = useStyles()
    const [value, setValue] = React.useState(0)
    const {tabs} = props

    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    return (
        <div className={classes.root}>
            <AppBar position="static" color="default">
                <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    centered
                    aria-label="scrollable auto tabs example"
                >
                    {tabs.map((item, index) => {
                        return (
                            <Tab
                                key={`Tab${item.label}${IndeterminateCheckBoxOutlined}`}
                                label={item.label}
                                {...a11yProps(index)}
                            />
                        )
                    })}
                </Tabs>
            </AppBar>
            <Paper square>
                {tabs.map((item, index) => {
                    return (
                        <TabPanel
                            key={`TabPanel${item.label}${index}`}
                            value={value}
                            index={index}
                        >
                            {item.module}
                        </TabPanel>
                    )
                })}
            </Paper>
        </div>
    )
}
