import AppBar from "@material-ui/core/AppBar"
import Box from "@material-ui/core/Box"
import Paper from "@material-ui/core/Paper"
import {makeStyles} from "@material-ui/core/styles"
import Tab from "@material-ui/core/Tab"
import Tabs from "@material-ui/core/Tabs"
import Typography from "@material-ui/core/Typography"
import PropTypes from "prop-types"
import React from "react"
import SplineChart from "../charts/SplineChart"
import StepChart from "../charts/StepChart"
import EvalExpTable from "../tables/EvalExpTable"

function TabPanel(props) {
    const {children, value, index, className, ...other} = props

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={0} className={className}>
                    <Typography component={"span"}>{children}</Typography>
                </Box>
            )}
        </div>
    )
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
}

function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        "aria-controls": `scrollable-auto-tabpanel-${index}`,
    }
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        width: "100%",
        backgroundColor: theme.palette.background.paper,
    },
    formControl: {
        marginLeft: "2rem",
        marginTop: "1rem",
        minWidth: "10rem",
    },
}))

export default function ScrollableTabsButtonAuto(props) {
    const classes = useStyles()
    const [value, setValue] = React.useState(0)

    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    return (
        <>
            <AppBar position="static" color="default">
                <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    scrollButtons="auto"
                    centered
                    aria-label="scrollable auto tabs example"
                >
                    <Tab label="Analog expressions" {...a11yProps(0)} />
                    <Tab label="Pulse expressions" {...a11yProps(1)} />
                    <Tab label="Digital Expressions" {...a11yProps(2)} />
                </Tabs>
            </AppBar>
            <Paper square>
                <TabPanel value={value} index={0}>
                    <SplineChart
                        diff={false}
                        updateAlarms={props.updateAlarms}
                        updateExpressions={props.updateExpressions}
                        data={props.data}
                        expressions={props.expressions}
                        alarms={props.alarms}
                    ></SplineChart>
                    <EvalExpTable
                        data={props.data}
                        expressions={props.expressions}
                        name={"Analog expressions"}
                    ></EvalExpTable>
                </TabPanel>
                <TabPanel value={value} index={1} className={classes.tabPanel}>
                    <SplineChart
                        diff={true}
                        updateAlarms={(i) => i}
                        updateExpressions={props.updatePulseExpressions}
                        data={props.pulseData}
                        expressions={props.pulseExpressions}
                        alarms={[]}
                    ></SplineChart>
                    <EvalExpTable
                        data={props.pulseData}
                        expressions={props.pulseExpressions}
                        name={"Pulse expressions"}
                    ></EvalExpTable>
                </TabPanel>
                <TabPanel value={value} index={2} className={classes.tabPanel}>
                    <StepChart
                        updateDigiExpressions={props.updateDigiExpressions}
                        data={props.digiData}
                        digiExpressions={props.digiExpressions}
                    ></StepChart>
                    <EvalExpTable
                        data={props.digiData}
                        expressions={props.digiExpressions}
                        name={"Digital expressions"}
                    ></EvalExpTable>
                </TabPanel>
            </Paper>
        </>
    )
}
