import {
    Checkbox,
    FormControl,
    Input,
    InputLabel,
    ListItemText,
    MenuItem,
    Select,
} from "@material-ui/core"
import {makeStyles} from "@material-ui/core/styles"
import {
    ArgumentAxis,
    Chart,
    CommonAxisSettings,
    CommonSeriesSettings,
    ConstantLine,
    Export,
    Grid,
    Label,
    Legend,
    Margin,
    ScrollBar,
    Series,
    Size,
    Tooltip,
    ValueAxis,
    ZoomAndPan,
} from "devextreme-react/chart"
import React from "react"

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
}

const useStyles = makeStyles((theme) => ({
    root: {
        margin: "0px 20px",
    },
    formControl: {
        marginLeft: "2rem",
        marginTop: "1rem",
        minWidth: "25rem",
    },
    menuItem: {
        width: "500",
    },
}))

function SplineChart(props) {
    const classes = useStyles()
    const dateFormatter = ({value, _}) =>
        new Date(value).toLocaleString("sk-SK", {
            dateStyle: "medium",
            timeStyle: "medium",
        })

    const customizeTooltip = (pointInfo) => {
        return {
            html: `<p>Date: ${dateFormatter({
                value: pointInfo.point.argument,
            })}<p/> <p>Value: ${pointInfo.point.originalValue}<p/>`,
        }
    }

    const countDiff = (data) => {
        let tmp_pulse_data = []
        data.reduce((acc, curr, index) => {
            tmp_pulse_data.push(countDiffObj(acc, curr))
            return curr
        }, {...data[0]})
        return tmp_pulse_data
    }

    const countDiffObj = (obj1, obj2) => {
        const res = {}
        for (let k in obj2) {
            if (obj2.hasOwnProperty(k))
                res[k] = isNaN(obj2[k])
                    ? obj2[k]
                    : (obj2[k] || 0) - (obj1[k] || 0)
        }
        return res
    }

    function onLegendClick(prop) {
        let isVis = prop.target.isVisible()
        if (isVis) {
            prop.target.hide()
        } else {
            prop.target.show()
        }
        props.updateExpressions(
            props.expressions.map((item, index) =>
                index === prop.target.index ? {...item, visible: !isVis} : item
            )
        )
    }

    return (
        <div className={classes.root}>
            {props.alarms.length > 0 && (
                <FormControl className={classes.formControl}>
                    <InputLabel id="demo-mutiple-checkbox-label">
                        Alarms
                    </InputLabel>
                    <Select
                        labelId="demo-mutiple-checkbox-label"
                        id="demo-mutiple-checkbox"
                        multiple
                        value={props.alarms.filter((item) => item.checked)}
                        onChange={(e) => {
                            let lastItem =
                                e.target.value[e.target.value.length - 1]
                            props.updateAlarms(
                                props.alarms.map((item) =>
                                    item.id === lastItem.id
                                        ? {...item, checked: !item.checked}
                                        : item
                                )
                            )
                        }}
                        input={<Input />}
                        renderValue={(selected) =>
                            selected.reduce(
                                (acc, item) => acc + item.name + ", ",
                                ""
                            )
                        }
                        MenuProps={MenuProps}
                    >
                        {props.alarms.map((item) => (
                            <MenuItem
                                key={`alarm_${item.id}`}
                                value={{...item}}
                                className={classes.menuItem}
                            >
                                <Checkbox checked={item.checked} />
                                <ListItemText
                                    primary={`${item.name} : ${item.value}`}
                                />
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            )}
            <Chart
                onLegendClick={onLegendClick}
                palette="Violet"
                dataSource={
                    props.diff
                        ? countDiff(props.data.slice(0).reverse())
                        : props.data.slice(0).reverse()
                }
                title="Expressions"
            >
                <CommonSeriesSettings argumentField="date" type={"spline"} />
                <CommonAxisSettings
                    maxValueMargin={0.5}
                    valueMarginsEnabled={true}
                >
                    <Grid visible={true} />
                </CommonAxisSettings>
                {props.expressions.map(function (item) {
                    return (
                        <Series
                            visible={item.visible}
                            key={item.value}
                            valueField={item.value}
                            name={item.name}
                        />
                    )
                })}

                {props.alarms.filter((item) => item.checked) && (
                    <ValueAxis placeholderSize={70}>
                        {props.alarms
                            .filter((item) => item.checked)
                            .map((item, index) => (
                                <ConstantLine
                                    key={`constant_line${index}`}
                                    width={2}
                                    value={item.value}
                                    color={`rgb(${(125 + index * 20) % 255},${
                                        (125 + index * 50) % 255
                                    },${(125 - index * 50) % 255})`}
                                    dashStyle="dash"
                                >
                                    <Label text={item.name} />
                                </ConstantLine>
                            ))}
                    </ValueAxis>
                )}

                <Margin bottom={20} />
                <ScrollBar visible={true} />
                <ZoomAndPan argumentAxis="both" valueAxis="both" />
                <Legend
                    verticalAlignment="bottom"
                    horizontalAlignment="center"
                />
                <Export enabled={true} />
                <Tooltip enabled={true} customizeTooltip={customizeTooltip} />
                <ArgumentAxis argumentType="datetime">
                    <Label customizeText={dateFormatter}></Label>
                </ArgumentAxis>
                <Size height={600} />
                <Margin right={50} />
            </Chart>
        </div>
    )
}

export default SplineChart
