import {Box, IconButton} from "@material-ui/core"
import Divider from "@material-ui/core/Divider"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import SettingsIcon from "@material-ui/icons/Settings"
import React from "react"

export default function TopNavSecondary(props) {
    return (
        <>
            <Box minHeight={80}>
                <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                >
                    <Grid item xs={12} md={5} lg={5}>
                        <Box
                            display="flex"
                            flexDirection="row"
                            my={2}
                            justifyContent="flex-start"
                            alignItems="flex-start"
                        >
                            <Typography variant="h2">
                                {props.heading}
                            </Typography>

                            {props.handleSettings && (
                                <Box ml={0}>
                                    <IconButton
                                        color="primary"
                                        aria-label="open settings"
                                        component="span"
                                        onClick={props.handleSettings}
                                    >
                                        <SettingsIcon />
                                    </IconButton>
                                </Box>
                            )}
                        </Box>
                    </Grid>

                    <Grid item xs={12} md={7} lg={7}>
                        <Box
                            display="flex"
                            flexDirection="row"
                            my={2}
                            justifyContent="flex-end"
                            alignItems="center"
                        >
                            {props.buttons &&
                                props.buttons.map((item, index) => (
                                    <Box key={index} ml={1}>
                                        {item}
                                    </Box>
                                ))}
                        </Box>
                    </Grid>
                </Grid>
            </Box>

            <Box my={2} marginBottom={4}>
                <Divider />
            </Box>
        </>
    )
}
