const storage = {
    getToken: () => {
        return window.localStorage.getItem("token") || ""
    },
    setToken: (token) => {
        window.localStorage.setItem("token", token)
    },
    getRefreshToken: () => {
        return localStorage.getItem("refreshToken") || ""
    },
    setRefreshToken: (token) => {
        return localStorage.setItem("refreshToken", token)
    },
    clearToken: () => {
        window.localStorage.removeItem(`token`)
        window.localStorage.removeItem(`refreshToken`)
    },
    isAuthUser: () => {
        return !!localStorage.getItem("token")
    },
    isAdmin: () => {
        return localStorage.getItem("is_superuser") === "true"
    },
    setAdmin: (isAdmin) => {
        return localStorage.setItem("is_superuser", isAdmin)
    },
    isViewer: () => {
        return localStorage.getItem("is_viewer") === "true"
    },
    setViewer: (isViewer) => {
        return localStorage.setItem("is_viewer", isViewer)
    },
    getDateFrom: () => {
        return localStorage.getItem("dateFrom")
            ? new Date(localStorage.getItem("dateFrom"))
            : new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 7)
    },
    getDateTo: () => {
        return localStorage.getItem("dateTo")
            ? new Date(localStorage.getItem("dateTo"))
            : new Date()
    },
    setDateFrom: (dateFrom) => {
        localStorage.setItem("dateFrom", dateFrom)
    },
    setDateTo: (dateTo) => {
        localStorage.setItem("dateTo", dateTo)
    },
    setUsername: (username) => {
        localStorage.setItem("username", username)
    },
    getUsername: () => {
        return localStorage.getItem("username") || ""
    },
    setUnit: (unit) => {
        localStorage.setItem("unit", unit)
    },
    getUnit: () => {
        return localStorage.getItem("unit") || ""
    },
    setId: (id) => {
        localStorage.setItem("id", id)
    },
    getId: () => {
        return localStorage.getItem("id") || ""
    },
    clearStorage: () => {
        localStorage.clear()
    },
    getSettings: () => {
        return JSON.parse(localStorage.getItem("settings")) || { decimals: 2, graphDateMode: "SMS_DATE" }
    },
    setSettings: (settings) => {
        return localStorage.setItem("settings", JSON.stringify(settings)) || { decimals: 2, graphDateMode: "SMS_DATE" }
    },
    setTriggered: (triggered) => {
        localStorage.setItem("triggered", triggered)
    },
    getTriggered: () => {
        return true
    },
}

export default storage
