import {Button} from "@material-ui/core"
import Box from "@material-ui/core/Box"
import CircularProgress from "@material-ui/core/CircularProgress"
import Divider from "@material-ui/core/Divider"
import {makeStyles} from "@material-ui/core/styles"
import {default as React, useEffect, useState} from "react"
import {Redirect, useParams} from "react-router-dom"
import AddUnitModal from "../modals/UnitModal"
import DeleteModal from "../../../components/modals/DeleteModal"
import BasicAlarmTable from "./tables/BasicAlarmTable"
import CustomExpressionsTable from "./tables/CustomExpressionsTable"
import DigitalTable from "./tables/DigitalTable"
import CustomTable from "../../../components/tables/EntriesTable"
import MissingAlarmTable from "./tables/MissingAlarmTable"
import PulseAlarmTable from "./tables/PulseAlarmTable"
import PulseCombinedAlarmTable from "./tables/PulseCombinedAlarmTable"
import TabsWrapper from "../../../components/tabs/TabsWrapper"
import TopNavSecondary from "../../../components/TopNavSecondary"
import {useAxios} from "../../../utils/request"
import DoneIcon from "@material-ui/icons/Done"
import CloseIcon from "@material-ui/icons/Close"
import MaterialStockTable from "./tables/MaterialStockAlarmTable";
import AnalogIntervalAlarmTable from "./tables/AnalogIntervalAlarmTable";

const expressionsTabs = (id) => [
    {
        label: "Analog Expressions",
        module: <CustomExpressionsTable id={id} url={"analog_expressions"} />,
    },
    {
        label: "Pulse Expressions",
        module: (
            <CustomExpressionsTable
                id={id}
                url={"pulse_expressions"}
                isPulseExpression
                custom_table_fields={[
                    {
                        title: "Stock enabled",
                        field: "stock_enabled",
                        render: (rowData) =>
                            !!rowData.stock_enabled ? (
                                <DoneIcon />
                            ) : (
                                <CloseIcon />
                            ),
                    },
                ]}
            />
        ),
    },
    {
        label: "Digital Expressions",
        module: <DigitalTable id={id} />,
    },
]
const alarmsTabs = (unitId, actionType="EMA") => [
    {
        label: "Analog Alarms",
        module: <BasicAlarmTable id={unitId} actionType={actionType} />,
    },
    {
        label: "Analog Interval Alarms",
        module: <AnalogIntervalAlarmTable id={unitId} actionType={actionType} />,
    },
    {
        label: "Digital alarms",
        module: <BasicAlarmTable id={unitId} digital={true} actionType={actionType}/>,
    },
    {
        label: "Combined pulse alarms",
        module: <PulseCombinedAlarmTable id={unitId} actionType={actionType}/>,
    },
    {
        label: "Pulse alarms",
        module: <PulseAlarmTable id={unitId} actionType={actionType}/>,
    },
    {
        label: "Missing alarm",
        module: <MissingAlarmTable id={unitId} actionType={actionType} />,
    },
    {
        label: "Material stock alarm",
        module: <MaterialStockTable id={unitId} actionType={actionType} />,
    },
]

const useStyles = makeStyles((theme) => ({
    spinner: {
        margin: "0 auto",
        display: "block",
    },
    divider: {
        margin: "1rem 0 1rem 0",
    },
    form: {
        maxWidth: 400,
    },
    root: {
        "& > *": {
            marginTop: theme.spacing(2),
            width: 400,
        },
    },
}))

export default function Units() {
    let {id} = useParams()
    const classes = useStyles()
    const [unit, setUnit] = useState("")
    const [modalAlert, setModalAlert] = useState(false)
    const [editModal, setEditModal] = useState(false)
    const [redirect, setRedirect] = React.useState(false)
    const {refetch} = useAxios(
        ["units", id],
        {
            url: `/units/${id}`,
            method: "GET",
        },
        {},
        (res) => {
            setUnit(res.data)
        }
    )

    const handleCloseEditModal = () => {
        setEditModal(false)
        updatePage()
    }

    const updatePage = () => {
        refetch()
    }

    useEffect(() => {
        updatePage()
        // eslint-disable-next-line
    }, [])

    if (redirect) {
        return <Redirect to={"/"} />
    }

    if (unit) {
        return (
            <>
                <TopNavSecondary
                    heading={`Unit ${unit.name}`}
                    buttons={[
                        <Button
                            className="btn primary"
                            variant="contained"
                            color="primary"
                            onClick={(_) => {
                                setEditModal(true)
                            }}
                        >
                            EDIT
                        </Button>,
                        <Button
                            className="btn primary"
                            variant="contained"
                            color="secondary"
                            onClick={(_) => {
                                setModalAlert(true)
                            }}
                        >
                            DELETE
                        </Button>,
                    ]}
                />

                {editModal && (
                    <AddUnitModal
                        open={editModal}
                        edit={editModal}
                        handleClose={handleCloseEditModal}
                    />
                )}

                <DeleteModal
                    open={modalAlert}
                    handleClose={() => {
                        setModalAlert(false)
                    }}
                    redirect={() => {
                        setRedirect(true)
                    }}
                    url={`/units/${id}/`}
                    deleteText={`DELETE UNIT`}
                    header={`Delete unit ${unit.name}?`}
                    alertText={"Unit was deleted"}
                />
                <TabsWrapper tabs={expressionsTabs(id)} />
                <Divider className={classes.divider} />
                <TabsWrapper tabs={alarmsTabs(id)} />
                <Divider className={classes.divider} />
                <CustomTable id={id} />
            </>
        )
    } else {
        return (
            <Box m={"auto"} className={classes.box}>
                <CircularProgress className={classes.spinner} />
            </Box>
        )
    }
}
