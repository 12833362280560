import {
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from "@material-ui/core"
import Button from "@material-ui/core/Button"
import Grid from "@material-ui/core/Grid"
import {makeStyles} from "@material-ui/core/styles"
import TextField from "@material-ui/core/TextField"
import React, {useEffect, useState} from "react"
import {useDispatch} from "react-redux"
import {setAlert} from "../../context/alert"
import {request} from "../../utils/request"

const useStyles = makeStyles((theme) => ({
    form: {
        "& > *": {
            margin: theme.spacing(1),
        },
        height: "15rem",
    },
    grid: {
        marginTop: "1rem",
    },
}))

export default function EditEntryModal(props) {
    const classes = useStyles()
    const dispatch = useDispatch()
    const [content, setContent] = useState(props.entry.content)
    const [checkContent, setCheckContent] = useState(false)
    const [date, setDate] = useState(props.entry.date.substring(0, 16))

    // eslint-disable-next-line
    const [loading, setLoading] = useState(false)

    function IsJsonString(str) {
        try {
            JSON.parse(str)
        } catch (e) {
            return false
        }
        return true
    }

    useEffect(() => {
        setCheckContent(!IsJsonString(`{${content}}`))
    }, [content])

    const handleSubmit = (e) => {
        e.preventDefault()

        if (checkContent) {
            dispatch(setAlert({severity: "error", message: "Not valid"}))
            return
        }
        const conf = {
            url: `/entries/${props.entry.id}/`,
            method: "PUT",
            data: {
                content: JSON.parse(`{${content}}`),
                date: `${date}${props.entry.date.substring(16, 25)}`,
            },
        }

        setLoading(true)
        request(conf)
            .then(() => {
                setLoading(false)

                dispatch(
                    setAlert({
                        status: "success",
                        message: "Entry was edited.",
                    })
                )
                props.handleClose()
            })
            .catch(() => {
                setLoading(false)
                dispatch(
                    setAlert({
                        severity: "error",
                        message: "Something went wrong.",
                    })
                )
            })
    }

    return (
        <div>
            <Dialog
                fullWidth={true}
                maxWidth={"sm"}
                open={props.open}
                onClose={props.handleClose}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogTitle id="scroll-dialog-title">
                    {"Edit entry"}
                </DialogTitle>
                <DialogContent dividers={true}>
                    <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                    >
                        <Grid item>
                            <form
                                className={classes.form}
                                id="add_expression_form"
                                onSubmit={handleSubmit}
                            >
                                <TextField
                                    label="Entry:"
                                    variant="standard"
                                    required
                                    fullWidth
                                    size="small"
                                    error={checkContent}
                                    helperText={
                                        checkContent
                                            ? "Wrong entry format!"
                                            : ""
                                    }
                                    onChange={(e) => {
                                        setContent(e.target.value)
                                    }}
                                    value={content}
                                    multiline
                                    rows={5}
                                />
                                <TextField
                                    id="datetime-local"
                                    label="Date"
                                    type="datetime-local"
                                    value={date}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onChange={(e) => {
                                        setDate(e.target.value)
                                    }}
                                />
                            </form>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Grid
                        container
                        justify="flex-end"
                        spacing={2}
                        className={classes.grid}
                    >
                        <Grid item>
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={props.handleClose}
                            >
                                Cancel
                            </Button>
                        </Grid>
                        <Grid item>
                            {loading ? (
                                <CircularProgress />
                            ) : (
                                <Button
                                    type="submit"
                                    form="add_expression_form"
                                    variant="contained"
                                    color="primary"
                                >
                                    {"Edit entry"}
                                </Button>
                            )}
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
        </div>
    )
}
