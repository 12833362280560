import {
    CircularProgress,
    Dialog,
    DialogActions,
    DialogTitle,
} from "@material-ui/core"
import Button from "@material-ui/core/Button"
import {makeStyles} from "@material-ui/core/styles"
import React, {useEffect} from "react"
import {useDispatch} from "react-redux"
import {setAlert} from "../../context/alert"
import {useAxios} from "../../utils/request"

const useStyles = makeStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    grid: {
        marginRight: "2rem",
        marginLeft: "2rem",
        marginBottom: "1rem",
    },

    formControl: {
        marginTop: 10,
        marginRight: 10,
    },
    expression: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "start",
    },
}))

export default function DeleteModal(props) {
    const classes = useStyles()
    const dispatch = useDispatch()
    const {data, refetch, isFetching, isLoading} = useAxios(
        ["delete", props.url],
        {
            url: props.url,
            method: "DELETE",
        }
    )

    useEffect(() => {
        if (data) {
            dispatch(
                setAlert({
                    status: "success",
                    message: props.alertText,
                })
            )
            props.handleClose()
            if (props.redirect) {
                props.redirect()
            }
        }
        // eslint-disable-next-line
    }, [isFetching])

    const deleteItem = () => {
        refetch()
    }

    return (
        <div>
            <Dialog
                open={props.open}
                onClose={props.handleClose}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogTitle id="scroll-dialog-title">
                    {props.header}
                </DialogTitle>
                <DialogActions className={classes.grid}>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={props.handleClose}
                    >
                        Cancel
                    </Button>

                    {isLoading ? (
                        <CircularProgress size="2rem" />
                    ) : (
                        <Button
                            onClick={deleteItem}
                            form="add_expression_form"
                            variant="contained"
                            color="primary"
                        >
                            {props.deleteText}
                        </Button>
                    )}
                </DialogActions>
            </Dialog>
        </div>
    )
}
