import {request, useAxios} from "../request";
import {checkIfUnitAll} from "./alarms";

export const EXPRESSION = {
    DIGITAL: {
        name: "Digital expression",
        url: "/digital_expressions/"
    },
    ANALOG: {
        name: "Analog expression",
        url: "/analog_expressions/"
    },
    PULSE: {
        name: "Pulse expression",
        url: "/pulse_expressions/"
    }
}


export function useExpressions(
    unitId = undefined,
    expressionType=EXPRESSION.ANALOG
) {
    return useAxios(
        [expressionType.url, unitId],
        {
            url: `${expressionType.url}`,
            params: {
                unit: unitId,
            },
            method: "GET",
        },
        {enabled: true},
    )
}

export function useEntries(
    unitId = undefined,
    dateFrom=undefined,
    dateTo=undefined
) {

    let dateFromEdited = new Date(
        dateFrom.setHours(0, 0, 0, 0) -
        dateFrom.getTimezoneOffset() * 60000
    ).toISOString().slice(0, -1)

    let dateToEdited = new Date(
        dateTo.setHours(23, 59, 59, 999) -
        dateTo.getTimezoneOffset() * 60000
    ).toISOString().slice(0, -1)

    return useAxios(
        ['entries', unitId, dateFromEdited, dateToEdited],
        {
            url: `/entries/`,
            params: {
                unit: unitId,
                limit: 1000000,
                date__gte: dateFromEdited,
                date__lte: dateToEdited,
                ordering: '-date'
            },
            method: "GET",
        },
        {enabled: true},
    )
}

export function getEntries(unitId, query){
    return request(
        {
            url: `/entries/`,
            method: "GET",
            params: {
                unit: unitId,
                ordering:
                    query.orderBy &&
                    (query.orderDirection === "desc"
                        ? "-"
                        : "") + query.orderBy?.field,
                limit: query.pageSize,
                offset: query.pageSize * query.page,
            }
        }
    )
}

export const useMaterialStockExpressions = (unit) =>
    useAxios(
    ["material_stocks", unit],
    {
        url: `/material_stocks/pulse_expressions/`,
        method: "GET",
        params: {
            unit: checkIfUnitAll(unit) || undefined,
        },
    }, {enabled: true},
)