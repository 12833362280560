import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from "@material-ui/core"
import Button from "@material-ui/core/Button"
import Card from "@material-ui/core/Card"
import CardHeader from "@material-ui/core/CardHeader"
import Checkbox from "@material-ui/core/Checkbox"
import Divider from "@material-ui/core/Divider"
import Grid from "@material-ui/core/Grid"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import {makeStyles} from "@material-ui/core/styles"
import React, {useState} from "react"
import {request} from "../../../utils/request"

function not(a, b) {
    return a.filter((value) => b.indexOf(value) === -1)
}

function intersection(a, b) {
    return a.filter((value) => b.indexOf(value) !== -1)
}

function union(a, b) {
    return [...a, ...not(b, a)]
}

const useStyles = makeStyles((theme) => ({
    root: {
        height: "30rem",
    },
    form: {
        "& > *": {
            margin: theme.spacing(1),
        },
    },
    grid: {
        marginTop: "1rem",
    },

    button: {
        margin: theme.spacing(0.5, 0),
    },
    cardHeader: {
        padding: theme.spacing(1, 2),
    },
    list: {
        width: 350,
        backgroundColor: theme.palette.background.paper,
        overflow: "auto",
    },
}))

export default function EditUnitsPerm(props) {
    const classes = useStyles()
    const [checked, setChecked] = useState([])
    const [left, setLeft] = useState([])
    const [right, setRight] = useState([])

    const leftChecked = intersection(checked, left)
    const rightChecked = intersection(checked, right)

    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value)
        const newChecked = [...checked]

        if (currentIndex === -1) {
            newChecked.push(value)
        } else {
            newChecked.splice(currentIndex, 1)
        }

        setChecked(newChecked)
    }

    const numberOfChecked = (items) => intersection(checked, items).length

    const handleToggleAll = (items) => () => {
        if (numberOfChecked(items) === items.length) {
            setChecked(not(checked, items))
        } else {
            setChecked(union(checked, items))
        }
    }

    const handleCheckedRight = () => {
        Promise.all(
            leftChecked.map((item) => {
                const conf = {
                    url: "/units/perms/",
                    data: {user_id: props.user.id, unit_id: item.id},
                    method: "POST",
                }
                return request(conf)
            })
        ).then((res) => {
            setRight(right.concat(leftChecked))
            setLeft(not(left, leftChecked))
            setChecked(not(checked, leftChecked))
        })
    }

    const handleCheckedLeft = () => {
        Promise.all(
            rightChecked.map((item) => {
                const conf = {
                    url: "/units/perms/",
                    data: {user_id: props.user.id, unit_id: item.id},
                    method: "DELETE",
                }
                return request(conf)
            })
        ).then((res) => {
            setLeft(left.concat(rightChecked))
            setRight(not(right, rightChecked))
            setChecked(not(checked, rightChecked))
        })
    }

    React.useLayoutEffect(() => {
        let units = []

        const conf = {
            url: "/units/",
            method: "GET",
        }
        request(conf).then((res) => {
            units = res.data.results
        })

        const conf2 = {
            url: "/units/perms/",
            method: "GET",
        }

        request(conf2).then((res) => {
            setLeft(units.filter((item) => !res.data[props.user.id][item.id]))
            setRight(units.filter((item) => res.data[props.user.id][item.id]))
        })

        // eslint-disable-next-line
    }, [])

    const customList = (title, items) => (
        <Card>
            <CardHeader
                className={classes.cardHeader}
                avatar={
                    <Checkbox
                        onClick={handleToggleAll(items)}
                        checked={
                            numberOfChecked(items) === items.length &&
                            items.length !== 0
                        }
                        indeterminate={
                            numberOfChecked(items) !== items.length &&
                            numberOfChecked(items) !== 0
                        }
                        disabled={items.length === 0}
                        inputProps={{"aria-label": "all items selected"}}
                    />
                }
                title={title}
                subheader={`${numberOfChecked(items)}/${items.length} selected`}
            />
            <Divider />
            <List className={classes.list} dense component="div" role="list">
                {items.map((value, index) => {
                    const labelId = `transfer-list-all-item-${value}-label`

                    return (
                        <ListItem
                            key={index}
                            role="listitem"
                            button
                            onClick={handleToggle(value)}
                        >
                            <ListItemIcon>
                                <Checkbox
                                    checked={checked.indexOf(value) !== -1}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{"aria-labelledby": labelId}}
                                />
                            </ListItemIcon>
                            <ListItemText
                                id={labelId}
                                primary={`${value.name}`}
                            />
                        </ListItem>
                    )
                })}
                <ListItem />
            </List>
        </Card>
    )

    return (
        <Dialog
            fullWidth={true}
            maxWidth={"md"}
            open={props.open}
            onClose={props.handleClose}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
        >
            <DialogTitle id="scroll-dialog-title">{"Edit units"}</DialogTitle>
            <DialogContent dividers={true}>
                <div className={classes.root}>
                    <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                        spacing={5}
                    >
                        <Grid item>
                            <Grid
                                container
                                spacing={2}
                                justify="center"
                                alignItems="flex-start"
                            >
                                <Grid item>
                                    {customList("All units", left)}
                                </Grid>
                                <Grid item>
                                    <Grid
                                        container
                                        direction="column"
                                        alignItems="center"
                                    >
                                        <Button
                                            variant="outlined"
                                            size="small"
                                            className={classes.button}
                                            onClick={handleCheckedRight}
                                            disabled={leftChecked.length === 0}
                                            aria-label="move selected right"
                                        >
                                            &gt;
                                        </Button>
                                        <Button
                                            variant="outlined"
                                            size="small"
                                            className={classes.button}
                                            onClick={handleCheckedLeft}
                                            disabled={rightChecked.length === 0}
                                            aria-label="move selected left"
                                        >
                                            &lt;
                                        </Button>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    {customList("Allowed units", right)}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            </DialogContent>
            <DialogActions>
                <Grid
                    container
                    justify="flex-end"
                    spacing={2}
                    className={classes.grid}
                >
                    <Grid item>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={props.handleClose}
                        >
                            Back
                        </Button>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    )
}
