import {
    Box,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    IconButton,
    Switch,
} from "@material-ui/core"
import Button from "@material-ui/core/Button"
import Grid from "@material-ui/core/Grid"
import {makeStyles} from "@material-ui/core/styles"
import TextField from "@material-ui/core/TextField"
import AddIcon from "@material-ui/icons/Add"
import DeleteIcon from "@material-ui/icons/Delete"
import React, {useEffect, useState} from "react"
import {useDispatch} from "react-redux"
import {v4} from "uuid"
import {setAlert} from "../../../../context/alert"
import {useAxios} from "../../../../utils/request"
import moment from "moment"

const useStyles = makeStyles((theme) => ({
    root: {
        "& > *": {
            margin: theme.spacing(1),
        },
        height: "35rem",
        display: "flex",
        flexDirection: "column",
        justifyContent: "start",
    },
    grid: {
        marginTop: "1rem",
    },

    formControl: {
        marginTop: 10,
        marginRight: 10,
    },
    expression: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "start",
        // paddingT: 10,
    },
}))

export default function AddCustomExpr(props) {
    const classes = useStyles()
    const dispatch = useDispatch()
    const [name, setName] = useState(props?.edit.name)
    const [stockEnabled, setStockEnabled] = useState(!!props?.edit?.stock_enabled)
    const [variable, setVariable] = useState(props?.edit?.variable)
    const [physicalUnit, setPhysicalUnit] = useState(props?.edit?.physical_unit)
    const [expressions, setExpressions] = useState(
        props.edit
            ? JSON.parse(props.edit.expressions).map((i) => ({
                  id: v4(),
                  ...i,
                  from: i.from.slice(0, 16),
                  to: i.to.slice(0, 16),
              }))
            : [
                  {
                      id: v4(),
                      expression: "",
                      from: `${moment().year() - 10}-01-01T00:00`,
                      to: `${moment().year() + 30}-01-01T00:00`,
                  },
              ]
    )
    const [wrongName, setWrongName] = useState(false)
    const [wrongExpr, setWrongExpr] = useState(false)

    const {refetch, isLoading} = useAxios(
        "analog_expressions",
        (() => {
            const exp_reducer = (acc, cur) => {
                return {
                    ...acc,
                    [cur.expression]: {
                        from: cur.from,
                        to: cur.to,
                    },
                }
            }

            const bodyFormData = new FormData()
            bodyFormData.append("unit", props.id)
            bodyFormData.append("name", name)
            bodyFormData.append("variable", variable)
            bodyFormData.append("physical_unit", physicalUnit)
            if (props.isPulseExpression) {
                bodyFormData.append("stock_enabled", stockEnabled)
            }
            bodyFormData.append(
                "intervals",
                JSON.stringify(expressions.reduce(exp_reducer, {}))
            )

            return {
                url: `/${props.url}/${props.edit ? props.edit.id + "/" : ""}`,
                method: props.edit ? "PUT" : "POST",
                data: bodyFormData,
            }
        })(),
        {},
        () => {
            props.handleClose()
            dispatch(
                setAlert({
                    status: "success",
                    message: props.edit
                        ? "Expression was updated"
                        : "New expression was created.",
                })
            )
        },
        () => {
            dispatch(
                setAlert({
                    severity: "error",
                    message: "Something went wrong.",
                })
            )
        },
        () => {
            props.handleClose()
        }
    )

    const validation = (expr) => {
        try {
            if (variable) {
                let tmp_expr = expr.replaceAll(variable, " 0 ")
                // eslint-disable-next-line
                eval(tmp_expr)
                return false
            }
            return true
        } catch (error) {
            return true
        }
    }

    useEffect(() => {
        setExpressions(
            expressions.map((item) => ({
                ...item,
                valid: validation(item.expression),
            }))
        )
        setWrongExpr(Boolean(expressions.find((item) => item.valid)))
        // eslint-disable-next-line
    }, [variable])

    useEffect(() => {
        setWrongExpr(Boolean(expressions.find((item) => item.valid)))
        // eslint-disable-next-line
    }, [expressions])

    const handleAddExpression = () => {
        const [lastItem] = expressions.slice(-1)
        setExpressions([
            ...expressions,
            {
                id: v4(),
                expression: "",
                from: lastItem.to,
                to: "2030-01-01T00:00",
            },
        ])
    }

    const handleChange = (id, expression, from, to) => {
        setExpressions(
            expressions.map((item) => {
                return item.id === id
                    ? {
                          ...item,
                          expression: expression,
                          from: from,
                          to: to,
                          valid: validation(expression),
                      }
                    : item
            })
        )
    }

    const handleDeleteExpr = (id) => {
        if (expressions.length > 1) {
            setExpressions(expressions.filter((item) => item.id !== id))
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        refetch()
    }

    const descriptionElementRef = React.useRef(null)
    React.useEffect(() => {
        if (props.open) {
            const {current: descriptionElement} = descriptionElementRef
            if (descriptionElement !== null) {
                descriptionElement.focus()
            }
        }
    }, [props.open])

    return (
        <Dialog
            open={props.open}
            onClose={props.handleClose}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
            maxWidth="md"
        >
            <DialogTitle id="scroll-dialog-title">
                {props.edit ? "Update " : "Add "} custom expression
            </DialogTitle>
            <DialogContent dividers={true}>
                <form
                    className={classes.root}
                    id="add_expression_form"
                    onSubmit={handleSubmit}
                >
                    <TextField
                        value={name}
                        error={wrongName}
                        helperText={
                            wrongName
                                ? "Name can not contain space character!"
                                : ""
                        }
                        onChange={(e) => {
                            setWrongName(e.target.value.indexOf(" ") >= 0)
                            setName(e.target.value)
                        }}
                        label="Name:"
                        variant="standard"
                        required
                        fullWidth
                        size="small"
                        className={classes.formControl}
                    />

                    <TextField
                        value={variable}
                        onChange={(e) => {
                            setVariable(e.target.value)
                        }}
                        label="Variable:"
                        variant="standard"
                        required
                        fullWidth
                        size="small"
                        className={classes.formControl}
                    />

                    <TextField
                        value={physicalUnit}
                        onChange={(e) => {
                            setPhysicalUnit(e.target.value)
                        }}
                        label="Physical unit:"
                        variant="standard"
                        required
                        fullWidth
                        size="small"
                        className={classes.formControl}
                    />
                    {props?.isPulseExpression && (
                        <FormControlLabel
                            control={
                                <Switch
                                    color="primary"
                                    checked={!!stockEnabled}
                                    onChange={(e) => {
                                        setStockEnabled(e.target.checked)
                                    }}
                                />
                            }
                            label="Stock enabled"
                        />
                    )}
                    <h3>Expressions:</h3>

                    {expressions.map((item, index) => {
                        return (
                            <Box key={index} className={classes.expression}>
                                <TextField
                                    label="Expression"
                                    error={item.valid}
                                    variant="standard"
                                    required
                                    size="small"
                                    className={classes.formControl}
                                    helperText={
                                        item.valid ? "Invalid expression" : ""
                                    }
                                    onChange={(e) => {
                                        handleChange(
                                            item.id,
                                            e.target.value,
                                            item.from,
                                            item.to
                                        )
                                    }}
                                    value={item.expression}
                                />

                                <TextField
                                    required
                                    id={`datetime-from${index}`}
                                    label="from"
                                    value={item.from}
                                    type="datetime-local"
                                    size="small"
                                    className={classes.formControl}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onChange={(e) => {
                                        handleChange(
                                            item.id,
                                            item.expression,
                                            e.target.value,
                                            item.to
                                        )
                                    }}
                                />
                                <TextField
                                    required
                                    id={`datetime-to${index}`}
                                    label="to"
                                    size="small"
                                    value={item.to}
                                    type="datetime-local"
                                    className={classes.formControl}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onChange={(e) => {
                                        handleChange(
                                            item.id,
                                            item.expression,
                                            item.from,
                                            e.target.value
                                        )
                                    }}
                                />
                                <IconButton
                                    onClick={() => {
                                        handleDeleteExpr(item.id)
                                    }}
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </Box>
                        )
                    })}
                    <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                    >
                        <Grid item>
                            <IconButton
                                onClick={() => {
                                    handleAddExpression()
                                }}
                            >
                                <AddIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                </form>
            </DialogContent>
            <DialogActions>
                <Grid
                    container
                    justify="flex-end"
                    spacing={2}
                    className={classes.grid}
                >
                    <Grid item>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={props.handleClose}
                        >
                            cancel
                        </Button>
                    </Grid>
                    <Grid item>
                        {isLoading ? (
                            <CircularProgress />
                        ) : (
                            <Button
                                type="submit"
                                form="add_expression_form"
                                variant="contained"
                                color="primary"
                                disabled={wrongName || wrongExpr}
                            >
                                {props.edit ? "Update " : "Add "} Custom
                                Expression
                            </Button>
                        )}
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    )
}
